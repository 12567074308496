<template>
  <div class="page-header-aligned">
    <b-form-group
      label="Space"
      label-align="right"
      label-cols="4"
      label-cols-lg="3"
      id="space-selection"
    >
      <b-form-select v-model="spaceId" v-bind:options="spaceChoices" />
    </b-form-group>
    <slot v-if="spaceId" v-bind:spaceId="spaceId" />
  </div>
</template>

<script>
import storeAuthStatusGetters from "@/mixins/store_auth_status_getters";
import { MERCHANT_SPACES_ROUTE } from "@/constants/relative_api_routes";

export default {
  name: "TheSpaceSelector",
  mixins: [storeAuthStatusGetters],
  props: {
    ownedOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      spaceId: null,
      spaceChoices: [],
    };
  },
  computed: {
    spacesBackendUrl() {
      return `${this.appConfig.BACKEND_API_URL}${MERCHANT_SPACES_ROUTE(
        this.userId
      )}`;
    },
  },
  methods: {
    getSpaces() {
      this.axios
        .get(this.spacesBackendUrl, {
          params: {
            merchant: this.ownedOnly ? this.userId : null,
            fields: "id,name",
          },
        })
        .then((response) => {
          this.spaceChoices = this.parseDataToChoices(response.data);
        });
    },
    parseDataToChoices(data) {
      let choices = [];
      for (let merchantSpace of data) {
        choices.push({
          value: merchantSpace.id,
          text: merchantSpace.name,
        });
      }
      return choices;
    },
  },
  created() {
    this.getSpaces();
  },
};
</script>

<style scoped lang="sass">
#space-selection
  width: 512px
  margin: 0 auto 15px
</style>
