<template>
  <div id="wallets">
    <h1>Wallets</h1>
    <TheSpaceSelector owned-only v-slot="slotProps">
      <TheWalletList
        v-bind:space-id="slotProps.spaceId"
        v-bind:key="slotProps.spaceId"
      />
    </TheSpaceSelector>
  </div>
</template>

<script>
import TheWalletList from "@/components/wallets/TheWalletList";
import storeAuthStatusGetters from "@/mixins/store_auth_status_getters";
import TheSpaceSelector from "@/components/base/TheSpaceSelector";

export default {
  name: "Wallets",
  components: { TheWalletList, TheSpaceSelector },
  mixins: [storeAuthStatusGetters],
};
</script>
