<template>
  <div id="invoice">
    <div id="qr-code-container" v-if="error.length === 0">
      <iframe v-if="invoiceUrl.length > 0" v-bind:src="invoiceUrl" />
      <Spinner v-else height="170px" />
    </div>
    <ErrorDisplay v-else v-bind:errors="[error]" />
  </div>
</template>

<script>
import Spinner from "@/components/base/Spinner";
import { CENTI_INVOICE_CREATE_ROUTE } from "@/constants/relative_integrator_app_routes";
import ErrorDisplay from "@/components/base/ErrorDisplay";
import { TOKEN_REFRESH_ACTION } from "@/constants/store_actions";

export default {
  name: "TheCashierInvoice",
  components: { ErrorDisplay, Spinner },
  props: {
    selectedOffers: {
      type: Array,
      required: true,
    },
    openValue: {
      type: Number,
      required: true,
    },
    spaceData: {
      type: Object,
      required: true,
    },
    merchantId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      invoiceUrl: "",
      error: "",
    };
  },
  computed: {
    requestData() {
      return {
        merchantId: this.merchantId,
        spaceId: this.spaceData.id,
        basket: Object.keys(this.selectedOffers).length
          ? Object.assign(
              {},
              ...this.selectedOffers.map((offerAndAmount) => ({
                [offerAndAmount[0].id]: offerAndAmount[1],
              }))
            )
          : null,
        openValue: this.openValue,
        responseType: "HTML_URL",
        type: "STANDARD",
      };
    },
    paymentId() {
      return this.invoiceUrl.split("/").pop().split("?").at(0);
    },
  },
  watch: {
    paymentId(value) {
      this.$emit("invoiceCreated", value);
    },
  },
  methods: {
    getInvoiceUrl() {
      return this.axios
        .post(
          this.appConfig.BACKEND_TOOLS_URL + CENTI_INVOICE_CREATE_ROUTE,
          this.requestData
        )
        .then((response) => {
          this.invoiceUrl = response.data;
        });
    },
  },
  created() {
    this.getInvoiceUrl().catch((error) => {
      if (error.response.status === 403) {
        this.$store.dispatch(`cashier/${TOKEN_REFRESH_ACTION}`).then(
          this.getInvoiceUrl().catch((error) => {
            this.error = error.response.data;
          })
        );
      } else {
        this.error = error.response.data;
      }
    });
  },
};
</script>

<style scoped lang="sass">
@import "src/assets/styles/mixins"

$iframe-width: 380
$iframe-height: 522
$parent-to-qr-ratio: 393

#invoice
  height: inherit

#qr-code-container
  height: $iframe-height * 1px
  width: $iframe-width * 1px
  overflow: hidden
  @include  scale-transform($scale: calc(#{$parent-to-qr-ratio} / #{$iframe-height}), $transform-origin: center 0)
  margin: 2.3vh auto

iframe
  border: none
  height: 110%
  width: 110%
  overflow: hidden
</style>
