<template>
  <b-modal id="introduction-modal" size="xl" lazy hide-footer hide-header>
    <div class="backdrop">
      <div id="first-time-introduction">
        <iframe title="Introduction" v-bind:src="presentationAddress" />
      </div>
      <div class="floating-footer">
        <b-form-checkbox v-model="disableIntroduction">
          Don't show this again
        </b-form-checkbox>
        <b-button
          pill
          variant="primary"
          id="close-introduction-button"
          v-on:click="closeIntroduction"
        >
          Got it, thanks!
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { SIGN_IN, SIGN_UP, WALLEE_INSTALLATION } from "@/constants/page_names";

export default {
  name: "TheIntroduction",
  props: {
    originPage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      disableIntroductionFlagStorageIndex: "disableIntroduction",
      disableIntroduction: false,
      allowedOrigins: [SIGN_IN, SIGN_UP, WALLEE_INSTALLATION],
      presentationAddress: "https://centi.ch/merchantintro/",
    };
  },
  computed: {
    hasUserJustSignedIn() {
      return this.allowedOrigins.includes(this.originPage);
    },
  },
  methods: {
    closeIntroduction() {
      this.$bvModal.hide("introduction-modal");
      window.localStorage.setItem(
        this.disableIntroductionFlagStorageIndex,
        this.disableIntroduction
      );
    },
  },
  mounted() {
    const disableIntroductionFlag =
      window.localStorage.getItem(this.disableIntroductionFlagStorageIndex) ||
      false.toString();
    if (!JSON.parse(disableIntroductionFlag) && this.hasUserJustSignedIn) {
      this.$bvModal.show("introduction-modal");
    }
  },
};
</script>

<style lang="sass">
@import '~bootstrap/scss/bootstrap.scss'
@import '~bootstrap-vue/src/index.scss'
@import 'src/assets/styles/mixins'

$iframe-width: 1140px
$iframe-height: 770px
$iframe-lg-scale: calc(800 / 1140)
$iframe-md-scale: calc(500 / 1140)

#introduction-modal
  .backdrop
    background-color: white

  .modal-body
    padding: 0

  .modal-content
    background-color: transparent
    border: none

  #first-time-introduction
    position: relative
    width: 100%
    padding-bottom: 67.5%
    overflow: hidden

    iframe
      position: absolute
      top: 0
      left: 0
      border: none
      height: calc(#{$iframe-height} * 1.1)
      width: $iframe-width
      overflow: hidden
      @include media-breakpoint-down(lg)
        @include  scale-transform($scale: $iframe-lg-scale)
      @include media-breakpoint-down(md)
        @include  scale-transform($scale: $iframe-md-scale)


  .floating-footer
    width: 100%
    text-align: center

    #close-introduction-button
      margin: 5px
</style>
