import Vue from "vue";
import Vuex from "vuex";
import * as mutationTypes from "@/constants/mutation_types";
import * as storeActions from "@/constants/store_actions";
import { TOKEN_REFRESH } from "@/constants/relative_api_routes";
import sessionCountdown from "./session_countdown";
import cashierModule from "./cashier_module";
import scannerModule from "./scanner_module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cashier: cashierModule,
    scanner: scannerModule,
  },
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    username: localStorage.getItem("username") || "",
    userId: localStorage.getItem("userId") || "",
    banner: localStorage.getItem("banner") || "",
    availableFeatures: localStorage.getItem("availableFeatures") || [],
    countdownWorker: null,
    remainingSessionTime: 0,
  },
  mutations: {
    [mutationTypes.AUTH_REQUEST](state) {
      state.status = "loading";
    },
    [mutationTypes.AUTH_SUCCESS](state, payload) {
      state.status = "success";
      state.token = payload.token;
      state.username = payload.username;
      state.userId = payload.userId;
      state.banner = payload.banner;
      state.availableFeatures = payload.availableFeatures;
    },
    [mutationTypes.AUTH_LOGOUT](state) {
      state.status = "";
      state.token = "";
      state.username = "";
      state.userId = "";
      state.banner = "";
      state.availableFeatures = [];
    },
    [mutationTypes.TOKEN_REFRESH_REQUEST](state) {
      state.status = "refresh";
    },
    [mutationTypes.TOKEN_REFRESH_SUCCESS](state, token) {
      state.status = "success";
      state.token = token;
    },
    [mutationTypes.TOKEN_REFRESH_ERROR](state) {
      state.status = "error";
    },
    [mutationTypes.SESSION_TIMER_START](state, time) {
      if (window.Worker) {
        state.remainingSessionTime = time;
        state.countdownWorker = new window.Worker(sessionCountdown);
        state.countdownWorker.postMessage(time);
        state.countdownWorker.onmessage = (event) => {
          state.remainingSessionTime = event.data;
        };
      }
    },
    [mutationTypes.SESSION_TIMER_STOP](state) {
      if (state.countdownWorker) {
        state.countdownWorker.terminate();
        state.countdownWorker = null;
      }
    },
  },
  actions: {
    [storeActions.LOGIN_ACTION]({ commit }, login_data) {
      return new Promise((resolve) => {
        commit(mutationTypes.AUTH_REQUEST);
        let token = login_data.token;
        let username = login_data.username;
        let userId = login_data.id;
        let banner = login_data.banner;
        let availableFeatures = login_data["available_features"];
        localStorage.setItem("token", token);
        localStorage.setItem("username", username);
        localStorage.setItem("userId", userId);
        localStorage.setItem("banner", banner);
        localStorage.setItem("availableFeatures", availableFeatures);
        Vue.prototype.axios.defaults.headers.common[
          "Authorization"
        ] = `Token ${token}`;
        commit(mutationTypes.AUTH_SUCCESS, {
          token,
          username,
          userId,
          banner,
          availableFeatures,
        });
        resolve(login_data);
      });
    },
    [storeActions.LOGOUT_ACTION]({ commit }) {
      return new Promise((resolve) => {
        commit(mutationTypes.AUTH_LOGOUT);
        if (window.Worker) {
          commit(mutationTypes.SESSION_TIMER_STOP);
        }
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        localStorage.removeItem("userId");
        localStorage.removeItem("banner");
        localStorage.removeItem("availableFeatures");
        delete Vue.prototype.axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    [storeActions.TOKEN_REFRESH_ACTION]({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        commit(mutationTypes.TOKEN_REFRESH_REQUEST);
        Vue.axios
          .get(Vue.prototype.appConfig.BACKEND_API_URL + TOKEN_REFRESH)
          .then((response) => {
            const token = response.data.token;
            localStorage.setItem("token", token);
            Vue.prototype.axios.defaults.headers.common[
              "Authorization"
            ] = `Token ${token}`;
            commit(mutationTypes.TOKEN_REFRESH_SUCCESS, token);
            resolve(response);
          })
          .catch((error) => {
            commit(mutationTypes.TOKEN_REFRESH_ERROR);
            dispatch(storeActions.LOGOUT_ACTION);
            reject(error);
          });
      });
    },
    [storeActions.SESSION_COUNTDOWN_ACTION]({ commit, state }, time) {
      return new Promise((resolve) => {
        if (state.countdownWorker) {
          commit(mutationTypes.SESSION_TIMER_STOP);
        }
        commit(mutationTypes.SESSION_TIMER_START, time);
        resolve();
      });
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return !!state.token;
    },
  },
});
