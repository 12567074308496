import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import VueAxios from "vue-axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import config from "./app-config";

document.title = "CENTI Portal";

Vue.use(VueApexCharts);
Vue.use(VueAxios, axios);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueTelInput);

Vue.component("apexchart", VueApexCharts);

const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.axios.defaults.headers.common[
    "Authorization"
  ] = `Token ${token}`;
}
Vue.config.productionTip = false;
Vue.prototype.appConfig = config;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
