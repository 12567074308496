<template>
  <div>
    <ThePromoRuleForm
      v-bind:allowed-options="allowedOptions"
      v-bind:token-choices="tokenChoices"
      v-bind:errors="errors"
      form-name="promo-rule-creation-form"
      v-on:submit="onSubmit($event)"
    />
    <b-modal
      id="modal-success"
      ok-only
      title="Success"
      v-on:hide="handleSuccessModalHidden"
    >
      <p class="my-4">Promotion rule has been created</p>
    </b-modal>
  </div>
</template>

<script>
import {
  PROMO_RULE_LIST_CREATE,
  TOKENS_ROUTE,
} from "@/constants/relative_api_routes";
import { PROMO_RULES } from "@/constants/page_names";
import { PROMO_RULE_CONDITIONS_CHOICES } from "@/constants/promo_rules";
import store_auth_status_getters from "@/mixins/store_auth_status_getters";
import ThePromoRuleForm from "@/components/promo_rules/ThePromoRuleForm";

export default {
  name: "PromoRuleCreate",
  components: { ThePromoRuleForm },
  mixins: [store_auth_status_getters],
  data() {
    return {
      allowedOptions: PROMO_RULE_CONDITIONS_CHOICES,
      tokenChoices: [],
      errors: {},
    };
  },
  computed: {
    url() {
      return `${this.appConfig.BACKEND_API_URL}${PROMO_RULE_LIST_CREATE(
        this.userId
      )}`;
    },
  },
  methods: {
    onSubmit(payload) {
      this.axios
        .post(this.url, payload)
        .then(() => this.$bvModal.show("modal-success"))
        .catch((error) => {
          this.$emit("fail", error.response.data);
          if (error.response.status >= 400 && error.response.status < 500) {
            this.errors = error.response.data;
          } else {
            this.errors = { _internal: error.response.statusText };
          }
        });
    },
    fetchTokens() {
      this.axios
        .get(this.appConfig.BACKEND_API_URL + TOKENS_ROUTE(this.userId))
        .then((response) => {
          this.tokenChoices = response.data.map((token) => {
            return {
              value: token.id,
              text: token.name,
            };
          });
        });
    },
    handleSuccessModalHidden() {
      this.$router.push({ name: PROMO_RULES });
    },
  },
  created() {
    this.fetchTokens();
  },
};
</script>
