<template>
  <div>
    <div id="tokens-table">
      <TableDisplay
        v-bind:items="tokens"
        v-bind:fields="fields"
        v-bind:labels="labels"
        icon="icon"
      >
        <template v-slot:action-buttons="slotProps">
          <b-button
            class="control-button"
            size="sm"
            variant="outline-danger"
            v-on:click="getRedemptionToken(slotProps.item)"
          >
            Redeem
          </b-button>
          <b-button
            class="control-button"
            size="sm"
            variant="outline-success"
            v-on:click="getReissuanceToken(slotProps.item)"
          >
            Reissue
          </b-button>
        </template>
      </TableDisplay>
    </div>
    <div>
      <b-button variant="primary" v-on:click="redirectTokenCreate">
        Create New Token
      </b-button>
    </div>
    <b-modal
      id="modal-redeem-token"
      class="tokens-modal"
      v-bind:title="`Redeem ${selectedToken.ticker_symbol} tokens`"
      hide-footer
      lazy
      static
    >
      <p class="tokens-modal-text-content">
        <strong class="note">NOTE: </strong>
        <strong>
          This will irreversibly withdraw your tokens from circulation!
        </strong>
      </p>
      <b-form
        id="token-redeem-form"
        class="token-supply-form"
        v-on:submit.stop.prevent="
          redeemToken(selectedToken.id, selectedToken.numberToRedeem)
        "
      >
        <b-form-group label="Number of tokens:" label-cols="6">
          <b-form-input
            type="number"
            v-model="selectedToken.numberToRedeem"
            min="1"
            step="1"
          />
        </b-form-group>
        <b-button
          variant="danger"
          type="submit"
          v-bind:disabled="isProcessingRequest"
        >
          <b-spinner variant="light" small v-if="isProcessingRequest" />
          <template v-else>Redeem</template>
        </b-button>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-reissue-token"
      class="tokens-modal"
      v-bind:title="`Reissue ${selectedToken.symbol} tokens`"
      hide-footer
      lazy
      static
    >
      <p>
        This operation will result in introducing more units of token
        {{ selectedToken.symbol }} into circulation.
      </p>
      <b-form
        id="token-reissue-form"
        class="token-supply-form"
        v-on:submit.stop.prevent="
          reissueToken(selectedToken.id, selectedToken.numberToReissue)
        "
      >
        <b-form-group label="Number of tokens" label-cols="6">
          <b-form-input
            type="number"
            v-model="selectedToken.numberToReissue"
            min="1"
            step="1"
          />
        </b-form-group>
        <b-button variant="primary" type="success">
          <b-spinner variant="light" small v-if="isProcessingRequest" />
          <template v-else>Reissue</template>
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import storeAuthStatusGetters from "@/mixins/store_auth_status_getters";
import dataReformatter from "@/mixins/data_reformatter";
import {
  TOKENS_ROUTE,
  TOKEN_REDEEM_ROUTE,
  TOKEN_REISSUE_ROUTE,
} from "@/constants/relative_api_routes";
import { TOKEN_CREATE } from "@/constants/page_names";
import TableDisplay from "@/components/base/TableDisplay";

export default {
  name: "TokenList",
  components: { TableDisplay },
  mixins: [storeAuthStatusGetters, dataReformatter],
  data() {
    return {
      tokens: [],
      fields: {
        basics: ["id", "icon", "name", "ticker_symbol", "registered_at"],
        details: [
          "name",
          "description",
          "contract_txid",
          "issuance_txid",
          "governing_law",
          "icon",
          "id",
          "registered_at",
          "terms",
          "ticker_symbol",
          "token_address",
          "valid_from",
          "valid_to",
        ],
      },
      labels: {
        registered_at: "Registered date",
      },
      selectedToken: {},
      isProcessingRequest: false,
    };
  },
  computed: {
    getTokensUrl() {
      return this.appConfig.BACKEND_API_URL + TOKENS_ROUTE(this.userId);
    },
  },
  methods: {
    async getTokens() {
      return await this.axios.get(this.getTokensUrl);
    },
    redirectTokenCreate() {
      this.$router.push({ name: TOKEN_CREATE });
    },
    getRedeemTokenUrl(tokenId) {
      return this.appConfig.BACKEND_API_URL + TOKEN_REDEEM_ROUTE(tokenId);
    },
    getReissueTokenUrl(tokenId) {
      return this.appConfig.BACKEND_API_URL + TOKEN_REISSUE_ROUTE(tokenId);
    },
    getRedemptionToken(token) {
      this.selectedToken = token;
      this.$bvModal.show("modal-redeem-token");
    },
    getReissuanceToken(token) {
      this.selectedToken = token;
      this.$bvModal.show("modal-reissue-token");
    },
    redeemToken(tokenId, number) {
      if (
        confirm(
          "Are you sure you want to redeem your tokens? This operation is irreversible!"
        )
      ) {
        this.isProcessingRequest = true;
        this.axios
          .post(this.getRedeemTokenUrl(tokenId), { number })
          .then(() => {
            this.$bvToast.toast(
              `${number} token${number > 1 ? "s" : ""} redeemed!`,
              {
                title: "Success",
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
              }
            );
          })
          .catch((error) => {
            console.error(error);
            this.$bvToast.toast("Tokens' redemption failed!", {
              title: "Error",
              variant: "danger",
              solid: true,
              toaster: "b-toaster-top-center",
            });
          })
          .finally(() => {
            this.$bvModal.hide("modal-redeem-token");
            this.isProcessingRequest = false;
          });
      }
    },
    reissueToken(token, number) {
      this.isProcessingRequest = true;
      this.axios
        .post(this.getReissueTokenUrl(token), { number })
        .then(() => {
          this.$bvToast.toast(
            `${number} token${number > 1 ? "s" : ""} issued!`,
            {
              title: "Success",
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center",
            }
          );
        })
        .catch((error) => {
          console.error(error);
          this.$bvToast.toast("Tokens' reissue failed!", {
            title: "Error",
            variant: "danger",
            solid: true,
            toaster: "b-toaster-top-center",
          });
        })
        .finally(() => {
          this.$bvModal.hide("modal-reissue-token");
          this.isProcessingRequest = false;
        });
    },
  },
  created() {
    this.getTokens().then((response) => {
      this.tokens = response.data;
      for (let i = 0; i < response.data.length; i++) {
        this.tokens[i].registered_at = this.parseDatetime(
          response.data[i].registered_at
        );
      }
    });
  },
};
</script>

<style lang="sass">
.note
  color: red

.tokens-modal-text-content
  text-align: justify

.control-button
  margin: 0 0.5em

.token-supply-form
  padding: 0 3em

.tokens-modal
  header
    text-align: start
</style>
