<template>
  <div v-if="errors.length > 0">
    <div
      v-for="(errorMsg, index) in errors"
      v-bind:key="index"
      class="alert alert-danger"
      role="alert"
    >
      {{ errorMsg }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorDisplay",
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
};
</script>
