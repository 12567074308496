<template>
  <div class="login">
    <h1>Sign in</h1>
    <TheSignInForm />
    <div>
      Don't have an account?
      <router-link :to="{ name: 'sign-up', query: $route.query }">
        Sign up</router-link
      >.
    </div>
  </div>
</template>

<script>
import TheSignInForm from "@/components/auth/TheSignInForm";

export default {
  name: "SignIn",
  components: { TheSignInForm },
};
</script>
