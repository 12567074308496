<template>
  <div id="receipt">
    <div id="receipt-offer-list">
      <div
        class="receipt-offer-list-row"
        v-for="([offer, amount], index) in basketContents"
        v-bind:key="index"
      >
        <div class="receipt-offer-details">
          <span>{{ amount }}x {{ offer.name }}</span>
          <span class="receipt-offer-price">{{ getPrice(offer, amount) }}</span>
        </div>
      </div>
    </div>
    <div id="receipt-summary" class="receipt-offer-details" ref="basketSummary">
      <span>
        <strong>Total</strong>
      </span>
      <span>{{ totalPrice }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheCashierReceipt",
  props: {
    basketContents: {
      type: Array,
      required: true,
    },
  },
  computed: {
    totalPrice() {
      let totalPrice = 0;
      for (let [offer, amount] of this.basketContents) {
        totalPrice += amount * offer.base_price;
      }
      return totalPrice.toFixed(2);
    },
  },
  methods: {
    getPrice(offer, amount) {
      return (amount * offer.base_price).toFixed(2);
    },
  },
};
</script>

<style scoped lang="sass">
$single-row-height: 36px

#receipt
  display: flex
  flex-direction: column
  font-size: 1.2rem
  height: 100%

#receipt-offer-list
  max-height: calc(9.5 * #{$single-row-height})
  overflow-y: scroll

.receipt-offer-details
  display: flex
  justify-content: space-between
  padding: 4px 10px
  text-align: left

.receipt-offer-price
  padding-left: 20px

#receipt-summary
  border-bottom: 0
  border-top: 1px solid lightgray
</style>
