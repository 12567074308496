<template>
  <div id="spaces-list" class="page-header-aligned">
    <b-tabs id="spaces-table" content-class="mt-3" align="center" lazy>
      <b-tab title="Owner" active>
        <TableDisplay
          v-bind:items="ownerSpaceList"
          v-bind:fields="fields.owner"
          v-bind:multiple-items-fields="multipleItemsFields.owner"
          icon="icon"
          v-bind:icon-aspect-ratio="2"
          v-on:editCalled="handleOwnerEditCall($event)"
          v-on:deleteCalled="handleDeleteCall($event)"
        >
          <template v-slot:details(id)="idDetailsProps">
            {{ idDetailsProps.data }}
            <qrcode-vue
              v-bind:value="generateQRCodeURL(idDetailsProps.data)"
              size="256"
            />
          </template>
        </TableDisplay>
      </b-tab>
      <b-tab title="Supplier">
        <TableDisplay
          v-bind:items="supplierSpaceList"
          v-bind:fields="fields.supplier"
          v-bind:multiple-items-fields="multipleItemsFields.supplier"
          icon="icon"
          v-bind:icon-aspect-ratio="2"
          v-on:editCalled="handleSupplierEditCall($event)"
        >
          <template v-slot:details(id)="idDetailsProps">
            {{ idDetailsProps.data }}
            <qrcode-vue
              v-bind:value="generateQRCodeURL(idDetailsProps.data)"
              size="256"
            />
          </template>
        </TableDisplay>
      </b-tab>
    </b-tabs>
    <div>
      <b-button variant="primary" v-on:click="spaceCreateRedirect">
        Create New Space
      </b-button>
    </div>
    <b-modal
      id="modal-delete"
      title="Delete space"
      cancel-variant="outline-primary"
      v-on:ok="deleteSpace"
    >
      <p class="my-4">Are you sure you want to delete the space?</p>
    </b-modal>
    <b-modal
      id="modal-payment-options-list"
      title="Payment options"
      hide-footer
      lazy
    >
      <ThePaymentOptionsManager
        v-bind="paymentOptionsDetailsData"
        v-on:listStateChanged="getSpaces"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  MERCHANT_SPACES_ROUTE,
  SPACE_ROUTE,
} from "@/constants/relative_api_routes";
import storeAuthStatusGetters from "@/mixins/store_auth_status_getters";
import TableDisplay from "@/components/base/TableDisplay";
import dataReformatter from "@/mixins/data_reformatter";
import { SPACE_CREATE, SPACE_DETAILS } from "@/constants/page_names";
import ThePaymentOptionsManager from "@/components/spaces/ThePaymentOptionsManager";
import QrcodeVue from "qrcode.vue";

export default {
  name: "SpaceList",
  components: { ThePaymentOptionsManager, TableDisplay, QrcodeVue },
  mixins: [storeAuthStatusGetters, dataReformatter],
  data() {
    return {
      supplierSpaceList: [],
      selectedSpace: null,
      fields: {
        owner: {
          basics: ["icon", "name", "start_date", "end_date"],
          details: [
            "id",
            "name",
            "registered_at",
            "updated_at",
            "start_date",
            "end_date",
            "icon",
            "base_currency",
            "suppliers",
          ],
        },
        supplier: {
          basics: ["icon", "name", "start_date", "end_date"],
          details: [
            "id",
            "name",
            "start_date",
            "end_date",
            "icon",
            "base_currency",
            "payment_options",
          ],
        },
      },
      multipleItemsFields: {
        owner: {
          suppliers: ["public_display_name"],
        },
        supplier: {
          payment_options: [
            "currency_display_name",
            "token",
            "ratio_to_base_currency",
          ],
        },
      },
    };
  },
  computed: {
    ownerSpaceList() {
      let ownedSpaces = [];
      for (let supplierSpace of this.supplierSpaceList) {
        if (supplierSpace.merchant === this.userId) {
          ownedSpaces.push(supplierSpace);
        }
      }
      return ownedSpaces;
    },
    merchantSpacesBackendUrl() {
      return `${this.appConfig.BACKEND_API_URL}${MERCHANT_SPACES_ROUTE(
        this.userId
      )}?expand=payment_options.token,suppliers&omit=secret_key`;
    },
    paymentOptionsDetailsData() {
      return {
        items: this.selectedSpace ? this.selectedSpace.payment_options : [],
        merchantId: this.userId,
        spaceId: this.selectedSpace ? this.selectedSpace.id : "",
      };
    },
  },
  methods: {
    getSpaces() {
      this.axios.get(this.merchantSpacesBackendUrl).then((response) => {
        this.supplierSpaceList = this.parseData(response.data);
        if (this.selectedSpace) {
          let updatedSpace = null;
          for (let supplierSpace of this.supplierSpaceList) {
            if (supplierSpace.id === this.selectedSpace.id) {
              updatedSpace = supplierSpace;
            }
          }
          this.selectedSpace = updatedSpace;
        }
      });
    },
    parseData(spaces) {
      for (let merchantSpace of spaces) {
        for (let paymentOption of merchantSpace.payment_options) {
          paymentOption.token =
            paymentOption.token !== null ? paymentOption.token.name : null;
          paymentOption.ratio_to_base_currency =
            paymentOption.ratio_to_base_currency !== null
              ? `(${paymentOption.ratio_to_base_currency} ${merchantSpace.base_currency})`
              : "(Market Rate)";
        }
        merchantSpace.registered_at = this.parseDatetime(
          merchantSpace.registered_at
        );
        merchantSpace.updated_at = this.parseDatetime(merchantSpace.updated_at);
        merchantSpace.start_date = this.parseDatetime(merchantSpace.start_date);
        merchantSpace.end_date = this.parseDatetime(merchantSpace.end_date);
      }
      return spaces;
    },
    handleDeleteCall(space) {
      this.selectedSpace = space;
      this.$bvModal.show("modal-delete");
    },
    deleteSpace() {
      this.axios
        .delete(
          this.appConfig.BACKEND_API_URL + SPACE_ROUTE(this.selectedSpace.id)
        )
        .then(() => {
          this.getSpaces();
        });
    },
    spaceCreateRedirect() {
      this.$router.push({ name: SPACE_CREATE });
    },
    handleOwnerEditCall(space) {
      this.$router.push({ name: SPACE_DETAILS, params: { id: space.id } });
    },
    handleSupplierEditCall(space) {
      this.selectedSpace = space;
      this.$bvModal.show("modal-payment-options-list");
    },
    generateQRCodeURL(eventId) {
      return "?event=" + eventId;
    },
  },
  created() {
    this.getSpaces();
  },
};
</script>
