const workerFunctionCode = function (event) {
  let remainingTime = event.data;
  setInterval(function () {
    remainingTime -= 1;
    postMessage(remainingTime);
  }, 1000);
}.toString();

const blob = new Blob([`onmessage = ${workerFunctionCode}`], {
  type: "javascript/worker",
});

export default window.URL.createObjectURL(blob);
