<template>
  <div id="space-details">
    <div class="form-container">
      <ApiForm
        v-on:success="$bvModal.show('modal-success')"
        v-bind="apiFormOptions"
      />
    </div>
    <b-modal
      id="modal-success"
      ok-only
      title="Success"
      v-on:hide="handleModalHidden"
    >
      <p class="my-4">Space has been changed</p>
    </b-modal>
  </div>
</template>

<script>
import ApiForm from "@/components/base/ApiForm";
import {
  MERCHANT_LIST_CREATE,
  SPACE_ROUTE,
} from "@/constants/relative_api_routes";
import { SPACES } from "@/constants/page_names";
import dataReformatter from "@/mixins/data_reformatter";
export default {
  name: "Space",
  components: { ApiForm },
  mixins: [dataReformatter],
  data() {
    return {
      merchantList: [],
      spaceDetails: {},
      spaceId: this.$route.params.id,
    };
  },
  methods: {
    handleModalHidden() {
      this.$router.push({ name: SPACES });
    },
    async getMerchants() {
      return await this.axios.get(this.url);
    },
    async getSpaceDetails() {
      return await this.axios.get(this.urlDetails);
    },
  },
  computed: {
    apiFormOptions() {
      return {
        formName: "space-details",
        targetUrl: `${this.appConfig.BACKEND_API_URL}${SPACE_ROUTE(
          this.spaceId
        )}`,
        action: "PUT",
        customFieldOptions: {
          suppliers: {
            multiple: true,
            choices: this.merchantList,
            default: this.spaceDetails["suppliers"],
          },
          name: { default: this.spaceDetails["name"] },
          start_date: { default: this.spaceDetails["start_date"] },
          end_date: { default: this.spaceDetails["end_date"] },
          base_currency: { default: this.spaceDetails["base_currency"] },
          icon: { default: this.spaceDetails["icon"], label: "Banner" },
        },
      };
    },
    url() {
      return this.appConfig.BACKEND_API_URL + MERCHANT_LIST_CREATE;
    },
    urlDetails() {
      return this.appConfig.BACKEND_API_URL + SPACE_ROUTE(this.spaceId);
    },
  },
  created() {
    this.getMerchants().then((response) => {
      for (let i = 0; i < response.data.length; i++) {
        this.merchantList.push({
          value: response.data[i].id,
          text: response.data[i].public_display_name,
        });
      }
    });

    this.getSpaceDetails().then((response) => {
      this.spaceDetails = response.data;
      this.spaceDetails["start_date"] = this.parseDatetimeToDateOnly(
        this.spaceDetails["start_date"]
      );
      this.spaceDetails["end_date"] = this.parseDatetimeToDateOnly(
        this.spaceDetails["end_date"]
      );
    });
  },
};
</script>

<style scoped></style>
