<template>
  <div class="home">
    <TheGreeting v-bind:username="user" v-bind:banner="banner" />
    <TheIntroduction
      v-if="isAuthenticated && previousPageName"
      v-bind:origin-page="previousPageName"
    />
  </div>
</template>

<script>
import TheGreeting from "@/components/home/TheGreeting.vue";
import TheIntroduction from "@/components/home/TheIntroduction";
import storeAuthStatusGetters from "@/mixins/store_auth_status_getters";

export default {
  name: "Home",
  mixins: [storeAuthStatusGetters],
  components: {
    TheGreeting,
    TheIntroduction,
  },
  data() {
    return {
      previousPageName: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousPageName = from.name;
    });
  },
};
</script>
