<template>
  <div>
    <div v-if="isProcessing">
      <Spinner />
    </div>
    <BaseForm
      v-else
      v-bind:field-data="fieldData"
      v-bind:model-data="transferData"
      v-bind:errors="errors"
      v-on:submit="sendTransferRequest"
    >
      Send
    </BaseForm>
  </div>
</template>

<script>
import BaseForm from "@/components/base/BaseForm";
import * as fieldTypes from "@/constants/form_field_types_identifiers";
import Spinner from "@/components/base/Spinner";
import { WALLET_ASSETS_SEND_ROUTE } from "@/constants/relative_api_routes";

export default {
  name: "TheWalletAssetsSendForm",
  components: { Spinner, BaseForm },
  props: {
    tokenId: {
      type: String,
      required: false,
      default: "",
    },
    decimals: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      fieldTypes,
      errors: {},
      transferData: {
        type: this.tokenId ? "Token" : "BSV",
        tokenId: this.tokenId,
      },
      isProcessing: false,
    };
  },
  computed: {
    fieldData() {
      return {
        to: {
          type: this.fieldTypes.TEXT_FIELD,
          label: "To",
          required: true,
          help_text: "Pay mail or wallet address",
        },
        amount: {
          type: this.fieldTypes.TEXT_FIELD,
          label: "Amount",
          required: true,
        },
      };
    },
  },
  methods: {
    sendTransferRequest() {
      this.errors = this.validate();
      if (Object.keys(this.errors).length === 0) {
        this.isProcessing = true;
        this.scaleAmountByTokenDecimals();
        this.axios
          .post(
            this.appConfig.BACKEND_API_URL + WALLET_ASSETS_SEND_ROUTE,
            this.transferData
          )
          .then(() => {
            this.$emit("success");
          })
          .catch((error) => {
            console.error(error);
            this.errors = { internal: "Transfer failed" };
            this.isProcessing = false;
          });
      }
    },
    validate() {
      let amount = parseFloat(this.transferData.amount);
      if (isNaN(amount)) {
        return { amount: "That's not a number..." };
      } else if (amount <= 0) {
        return { amount: "Must be greater than zero" };
      }
      return {};
    },
    scaleAmountByTokenDecimals() {
      this.transferData.amount *= Math.pow(10, this.decimals);
    },
  },
};
</script>
