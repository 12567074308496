<template>
  <div>
    <template v-if="content">{{ content }}</template>
    <b-spinner v-else v-bind:variant="spinnerVariant" />
  </div>
</template>

<script>
export default {
  name: "Placeholder",
  props: {
    content: {
      type: null,
      required: true,
    },
    spinnerVariant: {
      type: String,
      required: false,
      default: "primary",
    },
  },
};
</script>
