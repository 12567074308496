<template>
  <div id="qr-scanner">
    <b-navbar fixed="top" variant="primary" type="light">
      <b-navbar-brand href="#">
        <img
          src="@/assets/images/centi_white_logo.svg"
          alt="CENTI"
          id="centi-logo"
        />
        <img src="@/assets/images/icons/scanner.svg" alt="QR" />
        <span id="scanner-page-title">Check balance</span>
      </b-navbar-brand>
    </b-navbar>
    <div id="qr-container">
      <b-alert
        class="alert"
        variant="danger"
        v-bind:show="failAlertCountDown"
        dismissable
        fade
        v-on:dismissed="failAlertCountDown = 0"
      >
        {{ errorMessage }}
      </b-alert>

      <Spinner v-if="isLoading" height="170px" />
      <TheQRScanner
        v-on:decoded="captureContent($event)"
        v-show="isScanning && !isLoading"
        v-bind:keepScanning="true"
        v-bind:pauseBetweenScans="showCoinsTimeMs"
        v-bind:cancelable="false"
      />
      <TheCoinList
        v-if="!isScanning && !isLoading && !failAlertCountDown"
        v-bind:coins="coins"
      />
    </div>
  </div>
</template>

<script>
import TheQRScanner from "@/components/qr_scanner/TheQRScanner";
import TheCoinList from "@/components/qr_scanner/TheCoinList";
import Spinner from "@/components/base/Spinner.vue";
import { PAPER_WALLET_BALANCE_ROUTE } from "@/constants/relative_api_routes";

export default {
  name: "Scanner",
  components: { TheQRScanner, TheCoinList, Spinner },
  mixins: [],
  data() {
    return {
      isScanning: true,
      isLoading: false,
      showCoinsTimeMs: 6000,
      coins: [],
      failAlertCountDown: 0,
      alertTimer: 5,
      errorMessage: "",
      spaceData: {},
      storeNamespace: "scanner",
    };
  },
  computed: {
    balanceBackendUrl() {
      return this.appConfig.BACKEND_API_URL + PAPER_WALLET_BALANCE_ROUTE;
    },
  },
  methods: {
    startScanning() {
      this.isScanning = true;
      this.failAlertCountDown = 0;
      this.coins = [];
    },
    captureContent(content) {
      this.isLoading = true;

      this.axios
        .post(this.balanceBackendUrl, {
          key: content,
        })
        .then((response) => {
          this.isScanning = false;
          this.coins = response.data["coins"];
          setTimeout(() => this.startScanning(), this.showCoinsTimeMs);
        })
        .catch((error) => {
          this.showFailAlert(
            `Error while processing scanned url: ${error.message}`
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showFailAlert(message) {
      this.errorMessage = message;
      this.failAlertCountDown = this.alertTimer;
    },
  },
};
</script>

<style scoped lang="sass">
nav
  #scanner-page-title
    color: white
    font-weight: bold

  img
    margin-right: 10px

#qr-container
  margin-top: 30px
  height: 70vh
  max-width: 100vw
  background-color: white
  position: relative
  display: flex
  flex-direction: column
  justify-content: space-evenly
  align-items: center

  #scanner-container
    height: inherit
    width: inherit
    display: flex
    flex-direction: column
    justify-content: space-evenly
    align-items: center

  .alert
    position: relative
    top: 0
    width: 100%
</style>
