<template>
  <b-table
    bordered
    hover
    v-bind:items="items"
    v-bind:fields="fields.basics"
    headVariant="light"
  >
    <template v-slot:head()="data">
      {{ getLabel(data.column, data.label) }}
    </template>

    <template v-slot:cell(show_details)="row">
      <b-button
        size="sm"
        v-bind:variant="row.detailsShowing ? 'outline-primary' : 'primary'"
        v-on:click="row.toggleDetails"
        class="mr-2"
      >
        {{ row.detailsShowing ? "Hide" : "Show" }} Details
      </b-button>
    </template>

    <template v-if="icon" v-slot:[iconCell]="data">
      <div class="row-icon" v-bind:style="setIcon(data.value)" />
    </template>

    <template v-slot:row-details="row">
      <b-card>
        <b-row
          class="mb-2"
          v-for="(fieldName, index) in fields.details"
          v-bind:key="index"
        >
          <b-col sm="3" class="text-sm-right">
            <b>{{ getLabel(fieldName) }}:</b>
          </b-col>
          <b-col class="text-sm-left">
            <template v-if="fieldName === icon">
              <div
                class="details-icon"
                v-bind:style="setIcon(row.item[fieldName])"
              ></div>
            </template>
            <template
              v-else-if="
                multipleItemsFields === null ||
                multipleItemsFields[fieldName] === undefined
              "
            >
              <slot
                v-bind:name="`details(${fieldName})`"
                v-bind:data="row.item[fieldName]"
              >
                {{ row.item[fieldName] }}
              </slot>
            </template>
            <template v-else>
              <div
                class="mb-auto sub-item"
                v-for="(subItem, index) in row.item[fieldName]"
                v-bind:key="index"
              >
                <span
                  class="sub-item-field"
                  v-for="(subItemField, index) in multipleItemsFields[
                    fieldName
                  ]"
                  v-bind:key="index"
                >
                  {{ subItem[subItemField] }}
                </span>
              </div>
            </template>
          </b-col>
        </b-row>
        <div class="button-section">
          <div>
            <b-button
              v-if="hasEditListener"
              size="sm"
              variant="outline-primary"
              v-on:click="$emit('editCalled', row.item)"
              class="edit-event-button"
            >
              <b-icon icon="pencil" />
            </b-button>
            <b-button
              v-if="hasDeleteListener"
              size="sm"
              variant="outline-danger"
              v-on:click="$emit('deleteCalled', row.item)"
              class="delete-event-button"
            >
              <b-icon icon="trash" />
            </b-button>
            <slot name="action-buttons" v-bind:item="row.item"></slot>
          </div>
          <div>
            <b-button
              size="sm"
              variant="outline-primary"
              v-on:click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </div>
        </div>
      </b-card>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "TableDisplay",
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Object,
      required: true,
    },
    multipleItemsFields: {
      type: Object,
      required: false,
      default: null,
    },
    labels: {
      type: Object,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
    iconAspectRatio: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  computed: {
    hasDeleteListener() {
      return this.$listeners && this.$listeners.deleteCalled;
    },
    hasEditListener() {
      return this.$listeners && this.$listeners.editCalled;
    },
    iconCell() {
      return `cell(${this.icon})`;
    },
  },
  methods: {
    generateLabel(fieldName) {
      return (
        fieldName[0].toUpperCase() + fieldName.substring(1).replace(/_/g, " ")
      );
    },
    getLabel(fieldName, defaultLabel = null) {
      return this.labels === null || this.labels[fieldName] === undefined
        ? defaultLabel || this.generateLabel(fieldName)
        : this.labels[fieldName];
    },
    setIcon(iconUrl) {
      return {
        "background-image": `url(${iconUrl})`,
        "--aspect-ratio": this.iconAspectRatio,
      };
    },
  },
  created() {
    // That's super wrong, did it ever work?
    // eslint-disable-next-line vue/no-mutating-props
    this.fields.basics.push("show_details");
  },
};
</script>

<style scoped lang="sass">
::v-deep td
  white-space: pre-line

.button-section
  margin: auto
  width: 100px

  div
    display: flex
    justify-content: space-evenly
    padding-top: 5px

.row-icon, .details-icon
  box-shadow: 1px 1px 2px lightgrey
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  border-radius: 0.5rem

.details-icon
  height: 100px
  width: calc(100px * var(--aspect-ratio))

.row-icon
  height: 50px
  width: calc(50px * var(--aspect-ratio))
  margin: auto
</style>
