<template>
  <div v-if="isIdle">
    <span v-bind:id="cellId">
      {{ localValue }}
      <IconButton
        class="table-cell-edit-button"
        variant="primary"
        icon="pencil"
        size="sm"
        v-on:click="status = statusCodes.editing"
      />
    </span>
    <b-tooltip
      v-bind:disabled="!showError"
      v-bind:show.sync="showError"
      v-bind:target="cellId"
      variant="danger"
      >{{ errorMessage }}</b-tooltip
    >
  </div>
  <b-form-input
    class="editing-table-cell"
    v-else-if="isEditing"
    v-model="localValue"
    autofocus
    lazy
    v-bind:type="type"
    v-on:blur="updateValue"
    v-on:keyup.enter="$event.target.blur()"
  />
  <span class="processing-table-cell" v-else>
    {{ localValue }}
    <b-spinner
      class="processing-cell-spinner"
      small
      variant="secondary"
      label="Processing..."
    />
  </span>
</template>

<script>
import IconButton from "@/components/base/IconButton";

export default {
  name: "EditableTableCell",
  components: { IconButton },
  props: {
    identifier: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: null,
      required: true,
    },
    updateUrl: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: "text",
    },
  },
  data() {
    return {
      localValue: this.value,
      errorMessage: "",
      showError: false,
      status: "",
      statusCodes: {
        idle: "IDLE",
        editing: "EDITING",
        processing: "PROCESSING",
      },
    };
  },
  watch: {
    value: {
      handler: function (newValue) {
        this.localValue = newValue;
      },
      immediate: true,
    },
  },
  computed: {
    isIdle() {
      return this.status === this.statusCodes.idle;
    },
    isEditing() {
      return this.status === this.statusCodes.editing;
    },
    cellId() {
      return this.identifier.concat(
        "-",
        this.label.replace(/ /g, "-"),
        "-editable-cell"
      );
    },
  },
  methods: {
    displayError(errorMessage) {
      this.errorMessage = errorMessage;
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
    },
    updateValue() {
      this.status = this.statusCodes.processing;
      this.axios
        .patch(this.updateUrl, { [this.label]: this.localValue })
        .then(() => {
          this.status = this.statusCodes.idle;
          this.$emit("input", this.localValue);
        })
        .catch((error) => {
          console.error(error);
          this.localValue = this.value;
          this.status = this.statusCodes.idle;
          const errorMessage =
            error.response.status === 400
              ? `Invalid input for ${this.label}`
              : `An error occurred while updating ${this.label}`;
          this.displayError(errorMessage);
        });
    },
  },
  created() {
    this.status = this.statusCodes.idle;
  },
};
</script>

<style scoped lang="sass">
span
  display: flex
  align-items: center
  justify-content: flex-end

.table-cell-edit-button
  margin-left: 0.5em

.processing-table-cell
  color: grey

.processing-cell-spinner
  margin-left: 0.5em
</style>
