<template>
  <div>
    <div id="promo-rules-table">
      <TableDisplay
        v-bind:items="promoRules"
        v-bind:fields="fields"
        v-bind:labels="labels"
        v-on:editCalled="handleEditCall($event)"
        v-on:deleteCalled="handleDeleteCall($event)"
      />
    </div>
    <div>
      <b-button variant="primary" v-on:click="promoRuleCreateRedirect">
        Create New Rule
      </b-button>
    </div>
    <b-modal
      id="modal-delete"
      title="Delete rule"
      cancel-variant="primary"
      v-on:ok="deletePromoRule"
    >
      <p class="my-4">Are you sure you want to delete the promotion rule?</p>
    </b-modal>
  </div>
</template>

<script>
import TableDisplay from "@/components/base/TableDisplay";
import storeAuthStatusGetters from "@/mixins/store_auth_status_getters";
import dataReformatter from "@/mixins/data_reformatter";
import {
  PROMO_RULE_LIST_CREATE,
  PROMO_RULE_DETAILS_ROUTE,
} from "@/constants/relative_api_routes";
import { PROMO_RULE_CREATE, PROMO_RULE_DETAILS } from "@/constants/page_names";
import { getPromoRuleConditionName } from "@/constants/promo_rules";

export default {
  name: "PromoRuleList",
  components: { TableDisplay },
  mixins: [storeAuthStatusGetters, dataReformatter],
  data() {
    return {
      promoRules: [],
      selectedPromo: null,
      fields: {
        basics: [
          "id",
          "name",
          "promo_rule_type",
          "conditions",
          "return_token",
          "amount",
          "amount_type",
        ],
        details: [
          "id",
          "registered_at",
          "updated_at",
          "name",
          "promo_rule_type",
          "amount",
          "amount_type",
          "return_token",
          "conditions",
          "start_date",
          "end_date",
        ],
      },
      labels: {
        promo_rule_type: "Trigger",
        space: "Space",
      },
    };
  },
  computed: {
    url() {
      return `${this.appConfig.BACKEND_API_URL}${PROMO_RULE_LIST_CREATE(
        this.userId
      )}?expand=return_token`;
    },
  },
  methods: {
    async getPromoRules() {
      return await this.axios.get(this.url).then((response) => {
        this.promoRules = this.parseData(response.data);
      });
    },
    handleDeleteCall(promoRule) {
      this.selectedPromo = promoRule;
      this.$bvModal.show("modal-delete");
    },
    handleEditCall(promoRule) {
      this.$router.push({
        name: PROMO_RULE_DETAILS,
        params: { id: promoRule.id },
      });
    },
    promoRuleCreateRedirect() {
      this.$router.push({ name: PROMO_RULE_CREATE });
    },
    deletePromoRule() {
      this.axios
        .delete(
          this.appConfig.BACKEND_API_URL +
            PROMO_RULE_DETAILS_ROUTE(this.selectedPromo.id)
        )
        .then(() => {
          this.getPromoRules();
        });
    },
    parseConditionsData(promoRule) {
      let conditionList = promoRule.conditions.map((condition) =>
        getPromoRuleConditionName(condition)
      );
      return conditionList.join(",\n");
    },
    parseData(promoRules) {
      promoRules.forEach((promo) => {
        promo.return_token = promo.return_token.ticker_symbol;
        promo.start_date = this.parseDatetime(promo.start_date);
        promo.end_date = this.parseDatetime(promo.end_date);
        promo.registered_at = this.parseDatetime(promo.registered_at);
        promo.updated_at = this.parseDatetime(promo.updated_at);
        promo.conditions = this.parseConditionsData(promo);
      });
      return promoRules;
    },
  },
  created() {
    this.getPromoRules();
  },
};
</script>
