<template>
  <svg
    v-on:click="walletSync"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.06189 13C4.02104 12.6724 4 12.3387 4 12C4 7.58172 7.58172 4 12 4C14.5006 4 16.7332 5.14727 18.2002 6.94416M19.9381 11C19.979 11.3276 20 11.6613 20 12C20 16.4183 16.4183 20 12 20C9.61061 20 7.46589 18.9525 6 17.2916M9 17H6V17.2916M18.2002 4V6.94416M18.2002 6.94416V6.99993L15.2002 7M6 20V17.2916"
      stroke="#000000"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      v-bind:class="{ isSyncing: isSyncing }"
    />
  </svg>
</template>

<script>
import { WALLET_SYNC_ROUTE } from "@/constants/relative_api_routes";
export default {
  data() {
    return {
      isSyncing: false,
    };
  },
  methods: {
    async walletSync() {
      if (this.isSyncing) return;
      try {
        this.isSyncing = true;
        await this.axios.get(
          this.appConfig.BACKEND_API_URL + WALLET_SYNC_ROUTE
        );
        this.$emit("wallet-synced");
        this.isSyncing = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import "src/assets/styles/base"
svg
  cursor: pointer
  width: 2.4rem
  height: 2.4rem

  path
    stroke: $centi-blue
    transition: stroke-opacity 0.1s ease-in-out

    &.isSyncing
      stroke-opacity: 40%
</style>
