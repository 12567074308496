<template>
  <div class="offer-item" v-on:click="signalClick">
    <div class="offer-image" v-bind:style="setBackgroundImage(offer.icon)" />
    <span class="offer-label">{{ offer.name }}</span>
  </div>
</template>

<script>
export default {
  name: "TheCashierOfferListItem",
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    signalClick() {
      this.$emit("click");
    },
    setBackgroundImage(imageUrl) {
      return { "background-image": `url(${imageUrl})` };
    },
  },
};
</script>

<style scoped lang="sass">
$border-radius: 5px
$label-line-height: 24px

.offer-item
  background-color: white
  border-radius: $border-radius
  box-shadow: 1px 1px 3px lightgrey
  padding: 0 0 0.5vw
  overflow: hidden
  transition: opacity 0.25s, margin 0.1s

  &:hover
    opacity: 0.66

  &:active
    margin: 2%

.offer-image
  width: 100%
  height: 0
  padding-bottom: 100%
  margin-bottom: 10px
  overflow: hidden
  background-size: cover
  background-position: center
  background-repeat: no-repeat

.offer-label
  display: flex
  font-size: 1.2rem
  line-height: $label-line-height
  height: calc(2 * #{$label-line-height})
  overflow: hidden
  padding: 0 0.5vw
  flex-direction: column
</style>
