<template>
  <div id="global-promo-rules">
    <h1>Global Promotion Rules</h1>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "GlobalPromoRules",
};
</script>
