<template>
  <div id="greeting-card">
    <b-jumbotron
      v-bind:header="title"
      v-bind:lead="msg"
      v-bind:class="{ 'banner-display': !!banner }"
    />
    <div v-if="username" id="user-greeting">
      <img
        v-if="banner"
        id="banner-image"
        v-bind:src="banner"
        alt="We wanted to show your banner here, but we can't. :'("
      />
      <div id="greeting-message">
        <div class="message-container">
          <h2>Welcome, {{ username }}!</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheGreeting",
  props: {
    username: {
      type: String,
      required: false,
      default: null,
    },
    banner: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      title: "CENTI Portal",
      msg: "An app for managing your payments with CENTI",
    };
  },
};
</script>

<style scoped lang="sass">
@import "src/assets/styles/base"
$reserved-greeting-height: 311px

#greeting-card
  margin: 0 -30px

  .jumbotron
    background-color: $centi-blue
    color: white
    border-radius: 0
    margin-bottom: 0

    h1
      color: white
      text-align: center

  .banner-display
    padding: 15px
    height: 150px
    margin-bottom: 0

    h1
      margin: 0

#user-greeting
  position: relative
  padding-top: 70px

#greeting-message
  position: absolute
  top: 0
  width: 100%

  .message-container
    margin: auto
    padding: 6px 20px 3px
    width: fit-content
    width: -moz-fit-content
    border: 2px solid $centi-blue
    border-top: none
    border-radius: 0 0 1rem 1rem
    background: white

#banner-image
  height: calc(100vh - #{$reserved-greeting-height})
  max-height: 540px
  width: auto
  margin: auto
  border-radius: 1.5rem
  box-shadow: 3px 4px 11px lightgrey
</style>
