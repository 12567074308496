<template>
  <div class="filter">
    <div class="filter-top">
      <label>{{ label }}</label>
      <button type="button" v-on:click="$emit('delete')">×</button>
    </div>
    <div class="filter-bottom">
      <b-form-select
        v-model="localValue.mode"
        v-bind:options="filterModes"
        v-on:change="$emit('change', localValue)"
        v-on:input="$emit('input', localValue)"
      />
      <b-form-input
        v-model="localValue.value"
        v-on:change="$emit('change', localValue)"
        v-on:input="$emit('input', localValue)"
        v-on:update="$emit('update', localValue)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "TextBasedFilter",
  emits: ["change", "input", "update", "delete"],
  props: {
    filterModes: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return { localValue: this.value };
  },
};
</script>

<style scoped lang="sass">
.filter
    background-color: white
    max-width: 256px
    border-radius: 7px
    padding: 5px
    border: 1px deepskyblue solid

    .filter-top
        display: flex
        justify-content: space-between
        width: 100%

        label
            margin-bottom: 0

        button
            background-color: transparent
            border: 0

            &:active
                border: 0

    .filter-bottom
        display: flex
        width: 100%
</style>
