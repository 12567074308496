<template>
  <div id="spaces">
    <h1>Spaces</h1>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Spaces",
};
</script>
