<template>
  <div class="form-container">
    <BaseForm
      v-if="!didRequestVerification"
      v-bind:fieldData="fieldData"
      v-bind:modelData="credentials"
      v-bind:errors="errors"
      formName="sign-in"
      v-on:submit="isWhitelisted"
    >
      Sign in
    </BaseForm>
    <BaseForm
      v-else
      v-bind:fieldData="verificationFieldData"
      v-bind:modelData="verificationCredentials"
      v-bind:errors="verificationErrors"
      formName="sign-in"
      v-on:submit="signIn"
    >
      Verify
    </BaseForm>
    <b-modal id="otp-request-success-model" ok-only auto-focus-button="ok">
      Check your authentication app for the code.
    </b-modal>
  </div>
</template>

<script>
import { LOGIN_ACTION } from "@/constants/store_actions";
import { HOME_PAGE } from "@/constants/page_names";
import BaseForm from "@/components/base/BaseForm";
import {
  OTP_LOGIN_ROUTE,
  NON_CO_CONTROLLED_LOGIN_ROUTE,
  WHITELIST_LOOKUP_ROUTE,
} from "@/constants/relative_api_routes";
import { NETWORK_ERROR } from "@/constants/api_response_errors";

export default {
  name: "TheSignInForm",
  components: { BaseForm },
  data() {
    return {
      fieldData: {
        email: {
          type: "email",
          required: true,
          label: "Email",
        },
      },
      credentials: {},
      didRequestVerification: false,
      errors: {},
      isNonCoControlled: false,
      verificationCredentials: {},
      verificationErrors: {},
      networkConnectionErrorMsg:
        "We cannot reach our server at the moment. Please try again later.",
    };
  },
  computed: {
    targetRoute() {
      return this.$route.query.targetPath
        ? this.$route.query.targetPath
        : { name: HOME_PAGE };
    },
    verificationFieldData() {
      const verificationFieldData = {
        password: {
          type: "password",
          required: true,
          label: "Password",
        },
        code: {
          type: "text",
          required: true,
          label: "Authenticator code",
          autocomplete: "one-time-code",
        },
      };
      if (!this.isNonCoControlled) {
        delete verificationFieldData.password;
      }
      return verificationFieldData;
    },
  },
  methods: {
    async isWhitelisted() {
      try {
        const { data } = await this.axios.get(
          this.appConfig.BACKEND_API_URL +
            WHITELIST_LOOKUP_ROUTE(encodeURIComponent(this.credentials.email))
        );
        if (data.no_co_control) {
          this.isNonCoControlled = true;
        }
        this.didRequestVerification = true;
        this.$bvModal.show("otp-request-success-model");
      } catch (error) {
        if (error.message === NETWORK_ERROR || error.response?.status >= 500) {
          this.errors = { _internal: this.networkConnectionErrorMsg };
        } else {
          // Email not found
          if (error.response?.data?.detail) {
            this.errors = { _internal: error.response.data.detail };
          } else {
            this.errors = { _internal: error.response?.data?.error };
          }
        }
      }
    },
    async otpSignIn() {
      return await this.axios.post(
        this.appConfig.BACKEND_API_URL + OTP_LOGIN_ROUTE,
        {
          email: this.credentials.email,
          otp_code: this.verificationCredentials.code,
        }
      );
    },
    async nonCoControlledSignIn() {
      return await this.axios.post(
        this.appConfig.BACKEND_API_URL + NON_CO_CONTROLLED_LOGIN_ROUTE,
        {
          email: this.credentials.email,
          password: this.verificationCredentials.password,
          otp_code: this.verificationCredentials.code,
        }
      );
    },
    async signIn() {
      try {
        const response = this.isNonCoControlled
          ? await this.nonCoControlledSignIn()
          : await this.otpSignIn();

        await this.$store.dispatch(LOGIN_ACTION, response.data);
        this.$router.push(this.targetRoute);
      } catch (error) {
        if (error.message === NETWORK_ERROR || error.response.status >= 500) {
          this.verificationErrors = {
            _internal: this.networkConnectionErrorMsg,
          };
        } else {
          this.verificationErrors = {
            _internal: error.response.data.error,
          };
        }
      }
    },
  },
};
</script>
