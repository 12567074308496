<template>
  <TheAccessUrlManager
    v-bind:list-url="cashiersBackendUrl"
    v-bind:update-url="getUpdateUrl"
    v-bind:url-generator="getCashierPageUrl"
    item-name="cashier"
  />
</template>

<script>
import {
  CASHIER_LIST_CREATE,
  CASHIER_ROUTE,
} from "@/constants/relative_api_routes";
import { CASHIER } from "@/constants/page_names";
import TheAccessUrlManager from "@/components/base/TheAccessUrlManager";

export default {
  name: "TheCashierList",
  components: { TheAccessUrlManager },
  props: {
    spaceId: {
      type: String,
      required: true,
    },
  },
  computed: {
    cashiersBackendUrl() {
      return this.appConfig.BACKEND_API_URL + CASHIER_LIST_CREATE(this.spaceId);
    },
  },
  methods: {
    getUpdateUrl(cashierId) {
      return this.appConfig.BACKEND_API_URL + CASHIER_ROUTE(cashierId);
    },
    getCashierPageUrl(cashierId) {
      const cashierPagePath = this.$router.resolve({ name: CASHIER }).href;
      const query = new URLSearchParams({
        space: this.spaceId,
        id: cashierId,
      }).toString();
      return window.location.origin.concat("/", cashierPagePath, "?", query);
    },
  },
};
</script>
