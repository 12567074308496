<template>
  <div id="basket">
    <div id="basket-offer-list">
      <div
        class="basket-offer-list-row"
        v-for="([offer, amount], index) in basketContents"
        v-bind:key="index"
      >
        <div class="basket-offer-details">
          <span>{{ amount }}x {{ offer.name }}</span>
          <span class="basket-offer-price">{{ getPrice(offer, amount) }}</span>
        </div>
        <div class="basket-offer-controls">
          <IconButton
            class="basket-offer-control-button"
            icon="x"
            variant="outline-primary"
            v-on:click="editBasket(offer, 0)"
          />
          <template v-if="offer.id !== 0">
            <IconButton
              class="basket-offer-control-button"
              icon="dash"
              variant="outline-primary"
              v-on:click="editBasket(offer, amount - 1)"
            />
            <IconButton
              class="basket-offer-control-button"
              icon="plus"
              variant="outline-primary"
              v-on:click="editBasket(offer, amount + 1)"
            />
          </template>
        </div>
      </div>
    </div>
    <div id="basket-summary" class="basket-offer-details" ref="basketSummary">
      <span>
        <strong>Total</strong>
      </span>
      <span>{{ totalPrice }}</span>
    </div>
  </div>
</template>

<script>
import IconButton from "@/components/base/IconButton";
export default {
  name: "TheCashierBasket",
  components: { IconButton },
  props: {
    basketContents: {
      type: Array,
      required: true,
    },
  },
  computed: {
    totalPrice() {
      let totalPrice = 0;
      for (let [offer, amount] of this.basketContents) {
        totalPrice += amount * offer.base_price;
      }
      return totalPrice.toFixed(2);
    },
  },
  watch: {
    totalPrice() {
      this.$emit("totalPriceChanged", this.totalPrice > 0);
    },
  },
  methods: {
    editBasket(offer, amount) {
      this.$emit("basketEdited", { offer, amount });
    },
    getPrice(offer, amount) {
      return (amount * offer.base_price).toFixed(2);
    },
  },
};
</script>

<style scoped lang="sass">
$offer-controls-margin: 9px
$single-row-height: 98px

#basket
  display: flex
  flex-direction: column
  font-size: 1.4rem
  height: 100%

#basket-offer-list
  max-height: calc(4 * #{$single-row-height})
  overflow-y: scroll

.basket-offer-list-row
  border-bottom: 1px solid lightgray

.basket-offer-details
  display: flex
  justify-content: space-between
  padding: 6px 10px
  text-align: left

.basket-offer-controls
  display: flex
  justify-content: left
  margin-bottom: 8px

.basket-offer-control-button
  margin: 3px $offer-controls-margin

.basket-offer-price
  padding-left: 20px

#basket-summary
  border-bottom: 0
  border-top: 1px solid lightgray
</style>
