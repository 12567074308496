<template>
  <BaseForm v-bind="formData" v-on:submit="requestTopUp" />
</template>

<script>
import BaseForm from "@/components/base/BaseForm";
export default {
  name: "TheTopUpForm",
  components: { BaseForm },
  props: {
    walletAddresses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currencyChoices: [
        { text: "CHF", value: "CHF" },
        { text: "EUR", value: "EUR" },
        { text: "USD", value: "USD" },
      ],
      topUp: { currency: "", amount: 1 },
    };
  },
  computed: {
    formData() {
      return {
        fieldData: {
          currency: {
            type: "choice",
            choices: this.currencyChoices,
            required: true,
            label: "Currency",
          },
          amount: {
            type: "number",
            min: 1,
            max: 1000,
            required: true,
            label: "Amount",
          },
        },
        modelData: this.topUp,
        errors: {},
        formName: "top-up",
      };
    },
  },
  methods: {
    requestTopUp() {
      // eslint-disable-next-line no-unused-vars
      const data = this.walletAddresses.map((address) => {
        return {
          address,
          currency: this.topUp.currency,
          amount: this.topUp.amount,
        };
      });
      // For Top-up functionality mock only
      this.$emit("submit", this.topUp);
    },
  },
};
</script>
