<template>
  <label>
    {{ label }}
    <input
      class="form-control"
      ref="inputRef"
      type="text"
      v-bind:value="formattedValue"
    />
  </label>
</template>

<script>
// Sourced from original documentation: https://dm4t2.github.io/vue-currency-input/guide.html#creating-a-custom-component
import { useCurrencyInput } from "vue-currency-input";
import { watch } from "vue";

export default {
  name: "CurrencyInput",
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    options: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const { inputRef, formattedValue, setOptions, setValue } = useCurrencyInput(
      props.options
    );

    watch(
      () => props.value,
      (value) => {
        if (!value) {
          value = 0;
        }
        setValue(value);
      }
    );

    watch(
      () => props.options,
      (options) => {
        setOptions(options);
      }
    );

    return { inputRef, formattedValue };
  },
};
</script>

<style scoped lang="sass">
input
  width: 100%
</style>
