<template>
  <div class="filter">
    <div class="filter-top">
      <label>{{ label }}</label>
      <button v-if="!hideDelete" type="button" v-on:click="$emit('delete')">
        ×
      </button>
    </div>
    <b-form-select
      v-model="localValue"
      v-bind:options="options"
      v-on:change="$emit('change', localValue)"
      v-on:input="$emit('input', localValue)"
    />
  </div>
</template>
<script>
export default {
  name: "SimpleFilter",
  emits: ["change", "input", "delete"],
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: null,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    hideDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
};
</script>
<style scoped lang="sass">
.filter
    background-color: white
    max-width: 256px
    border-radius: 7px
    padding: 5px
    border: 1px deepskyblue solid

    label
        margin-bottom: 0px
    .filter-top
        display: flex
        justify-content: space-between
        width: 100%

        button
            background-color: transparent
            border: 0

            &:active
                border: 0
</style>
