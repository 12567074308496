<template>
  <b-navbar fixed="top" variant="primary" type="light">
    <b-navbar-brand>
      <router-link v-bind:to="homePageLocation" exact>
        <img src="@/assets/images/centi_white_logo.svg" alt="CENTI" />
      </router-link>
    </b-navbar-brand>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="isAuthenticated">
        <template v-for="(siteData, name) in pages">
          <template v-if="siteData.isVisible">
            <b-nav-item-dropdown
              v-if="
                siteData.subPages &&
                siteData.subPages.some((subPage) => subPage.visibleSubPages)
              "
              class="nav-dropdown"
              v-bind:key="name"
              variant="primary outline-light"
              left
              no-caret
            >
              <template #button-content>
                <router-link
                  v-bind:to="siteData.location"
                  class="dropdown-toggle"
                >
                  <img
                    :src="getIconUrl(siteData.icon)"
                    :alt="siteData.name"
                    class="dd-img"
                  />
                  {{ siteData.name }}
                </router-link>
              </template>
              <b-dropdown-item
                v-for="subPage in siteData.subPages.filter(
                  (menuPage) => menuPage.visibleSubPages
                )"
                v-bind:key="subPage.name"
                class="dd-item"
              >
                <router-link :to="subPage.location" class="dd-menu">
                  {{ subPage.name }}
                </router-link>
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <router-link
              v-else
              v-bind:key="siteData.name"
              v-bind:to="siteData.location"
              class="area"
            >
              <img
                v-bind:src="getIconUrl(siteData.icon)"
                v-bind:alt="siteData.name"
              />
              {{ siteData.name }}
            </router-link>
          </template>
        </template>
      </b-navbar-nav>
      <b-navbar-nav id="right-panel">
        <TheSessionTimer v-if="isAuthenticated && areWorkersSupported" />
        <TheNavbarAuthButton />
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import TheNavbarAuthButton from "@/components/app/TheNavbarAuthButton";
import * as pageNames from "@/constants/page_names";
import store_auth_status_getters from "@/mixins/store_auth_status_getters";
import TheSessionTimer from "@/components/app/TheSessionTimer";

const featureNames = {
  [pageNames.TOKENS]: "TOKENS",
  [pageNames.SPACES]: "SPACES",
  [pageNames.OFFERS]: "OFFERS",
  [pageNames.WALLET]: "WALLET",
  [pageNames.WALLETS]: "PAPER_WALLETS",
  [pageNames.CASHIERS]: "CASHIERS",
  [pageNames.PROMO_RULES]: "PROMO_RULES",
  [pageNames.GLOBAL_PROMO_RULES]: "GLOBAL_PROMO_RULES",
  [pageNames.COLLECTORS]: "COLLECTORS",
  [pageNames.DASHBOARD]: "DASHBOARD",
};

export default {
  name: "NavbarComponent",
  components: { TheNavbarAuthButton, TheSessionTimer },
  mixins: [store_auth_status_getters],
  data() {
    return {
      homePageLocation: { name: pageNames.HOME_PAGE },
      pages: {
        dashboard: {
          name: "Dashboard",
          location: { name: pageNames.DASHBOARD },
          icon: "tokens",
        },
        wallet: {
          name: "Wallet",
          location: { name: pageNames.WALLET },
          icon: "wallets",
        },
        tokens: {
          name: "Tokens",
          location: { name: pageNames.TOKENS },
          icon: "tokens",
        },
        spaces: {
          name: "Spaces",
          location: { name: pageNames.SPACES },
          icon: "spaces",
          subPages: [
            {
              name: "Cashiers",
              location: { name: pageNames.CASHIERS },
              icon: "cashier",
            },
            {
              name: "Collectors",
              location: { name: pageNames.COLLECTORS },
              icon: "scanner",
            },
            {
              name: "Offers",
              location: { name: pageNames.OFFERS },
              icon: "offers",
            },
            {
              name: "Wallets",
              location: { name: pageNames.WALLETS },
              icon: "wallets",
            },
          ],
        },
        promoRules: {
          name: "Promotions",
          location: { name: pageNames.PROMO_RULES },
          icon: "promo_rules",
        },
        globalPromoRules: {
          name: "Global Promos",
          location: { name: pageNames.GLOBAL_PROMO_RULES },
          icon: "promo_rules",
        },
      },
    };
  },
  computed: {
    areWorkersSupported() {
      return !!window.Worker;
    },
    availableFeatures() {
      return this.$store.state.availableFeatures;
    },
  },
  watch: {
    availableFeatures: {
      handler(newValue) {
        this.updatePagesVisibility(newValue);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getIconUrl(iconName) {
      return require(`@/assets/images/icons/${iconName}.svg`);
    },
    updatePagesVisibility(features) {
      Object.values(this.pages).forEach((siteData) => {
        siteData.isVisible = features.includes(
          featureNames[siteData.location.name]
        );
        if (siteData.subPages) {
          siteData.subPages.forEach((subPage) => {
            subPage.visibleSubPages = features.includes(
              featureNames[subPage.location.name]
            );
          });
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles/base"

nav
  box-shadow: 0 0 5px

.dd-menu
  display: flex
  align-self: left
  font-weight: bold
  opacity: 1
  color: $centi-blue

  &:active
    color: $centi-blue
    opacity: 0.55
    pointer-events: auto
    background-color: none

  &:hover
    color: $centi-blue
    opacity: 0.55
    text-decoration: none

.dropdown-toggle
  display: flex
  align-items: center
  justify-content: center
  align-self: center
  font-weight: bold
  color: white

  &.router-link-active
    color: white
    opacity: 0.55
    pointer-events: auto

  &:hover
    color: white
    opacity: 0.55
    text-decoration: none

.navigation-auth
  display: flex
  align-items: center
  justify-content: right
  color: white


.navbar-collapse
  justify-content: flex-end

nav
  box-shadow: 0 0 5px

  #right-panel
    margin-left: 10px

  .navbar-nav
    .nav-dropdown
      margin-right: 26px

    .nav-dropdown, .area
      img
        margin-right: 8px

    .area
      display: flex
      align-items: center
      justify-content: center
      align-self: center
      font-weight: bold
      color: white
      margin-right: 34px

      &.router-link-active
        color: white
        opacity: 0.55
        pointer-events: none

      &:hover
        color: white
        opacity: 0.55
        text-decoration: none

.dd-item
  padding: 0
  align-items: flex-start

  &:active
    color: white
    opacity: 0.55
    pointer-events: auto
    background-color: transparent

  &:hover
    color: $centi-blue
    opacity: 1
    text-decoration: none
</style>
