<template>
  <div id="tokens">
    <h1>Tokens</h1>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Tokens",
};
</script>
