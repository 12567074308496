<template>
  <b-form v-bind:id="`sign-up-form`" v-on:submit.stop.prevent="onSubmit">
    <ErrorDisplay v-bind:errors="nonFieldErrors" v-bind:id="`sign-up-errors`" />
    <div id="fields-container">
      <GenericFormField
        v-for="(fieldName, index) in genericFields"
        v-bind:key="index"
        v-model="modelData[fieldName]"
        v-bind:field-name="fieldName"
        v-bind:field-properties="fieldData[fieldName]"
        v-bind:error="errors[fieldName]"
      />
      <b-form-group
        v-bind:id="`$sign-up-input-group-paymail`"
        v-bind:label-for="`paymail-input`"
        label-align="right"
        label-cols="4"
        label-cols-lg="3"
        v-bind:invalid-feedback="getError('paymail')"
        v-bind:state="getState('paymail')"
      >
        <template v-slot:label>
          <strong> Paymail prefix </strong>
        </template>
        <div class="row">
          <div class="col">
            <b-input-group>
              <b-form-input
                v-bind:id="`paymail-input`"
                v-model="modelData['paymail']"
                type="text"
                v-bind:state="getState('paymail')"
              />
              <b-input-group-append>
                <b-input-group-text>@centi.ch</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-1 center-alignment form-input-appendix">
            <b-icon v-bind:id="`paymail-info`" icon="info-circle" />
            <b-tooltip
              v-bind:target="`paymail-info`"
              triggers="hover"
              variant="info"
            >
              {{ fieldData["paymail"]["help_text"] }}
            </b-tooltip>
          </div>
        </div>
      </b-form-group>
      <GenericFormField
        v-model="modelData['mnemonic_phrase']"
        field-name="mnemonic_phrase"
        v-bind:field-properties="fieldData['mnemonic_phrase']"
        v-bind:error="errors['mnemonic_phrase']"
      />
    </div>

    <b-button v-bind:disabled="!isFormFilled" type="submit" variant="primary">
      Sign up
    </b-button>
  </b-form>
</template>

<script>
import ErrorDisplay from "@/components/base/ErrorDisplay";
import GenericFormField from "@/components/base/GenericFormField";
import basicForm from "@/mixins/basic_form";

export default {
  name: "TheSignUpForm",
  components: { ErrorDisplay, GenericFormField },
  mixins: [basicForm],
  data() {
    return {
      genericFields: Object.keys(this.fieldData).filter(
        (fieldName) => !["paymail", "mnemonic_phrase"].includes(fieldName)
      ),
    };
  },
};
</script>
