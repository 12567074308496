<template>
  <b-table
    bordered
    hover
    v-bind:sticky-header="tableHeight"
    v-bind:items="history"
    v-bind:fields="fields"
    headVariant="light"
  >
    <template v-slot:cell(image)="data">
      <div class="token-icon">
        <img v-bind:src="data.value" v-bind:alt="data.item.symbol" />
      </div>
    </template>
    <template v-slot:cell()="data">
      <div v-bind:class="{ spending: isSpendingRecord(data.item) }">
        {{ data.value }}
      </div>
    </template>
    <template v-slot:custom-foot>
      <b-tr v-if="isLoadingHistory || nextPageToken">
        <b-td v-bind:colspan="fields.length">
          <b-spinner v-if="isLoadingHistory" variant="primary" />
          <b-button
            v-else
            variant="primary"
            v-on:click="getWalletHistory(nextPageToken)"
          >
            Load more
          </b-button>
        </b-td>
      </b-tr>
    </template>
  </b-table>
</template>

<script>
import { WALLET_HISTORY_RETRIEVE_ROUTE } from "@/constants/relative_api_routes";

export default {
  name: "TheWalletHistoryList",
  props: {
    tableHeight: {
      type: String,
      required: true,
    },
    paymail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "image", label: "Icon" },
        "amount",
        { key: "outAddress", label: "To/From" },
        "timestamp",
        { key: "txId", label: "TxID" },
      ],
      history: [],
      nextPageToken: null,
      isLoadingHistory: false,
    };
  },
  methods: {
    isSpendingRecord(item) {
      return item.amount[0] === "-";
    },
    getWalletHistory() {
      this.isLoadingHistory = true;
      this.axios
        .get(this.appConfig.BACKEND_API_URL + WALLET_HISTORY_RETRIEVE_ROUTE, {
          params: { nextPageToken: this.nextPageToken },
        })
        .then((response) => {
          console.log(response.data);
          this.nextPageToken = response.data.meta.nextPageToken;
          this.history = this.history.concat(this.processData(response.data));
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoadingHistory = false;
        });
    },
    flattenHistories(histories) {
      var flatHistories = [];
      histories.forEach((history) =>
        history.to.forEach((addressee) => {
          flatHistories = flatHistories.concat(
            Object.assign({}, history, addressee)
          );
        })
      );
      return flatHistories;
    },
    processData(responseData) {
      var histories = this.flattenHistories(responseData.histories);
      histories.forEach((history) => {
        if (history.type === "debit") {
          history.outAddress = history.to;
          history.amount *= -1;
        } else {
          history.outAddress = history.from;
        }

        if (!history.tokenId) {
          history.amount = String(history.amount / 10 ** 8);
          history.image = require(`@/assets/images/bsv_icon.png`);
          history.symbol = history.protocol;
        } else {
          if (history.decimals) {
            history.amount = (history.amount / 100).toFixed(2);
          }
          history.amount = String(history.amount);
          history.symbol = history.tokenId.split("-")[1];
        }
      });
      return histories;
    },
    setIcon(iconUrl) {
      return {
        "background-image": `url(${iconUrl})`,
      };
    },
  },
  created() {
    this.getWalletHistory();
  },
};
</script>

<style lang="sass">
.spending
  color: crimson

.token-icon
  object-fit: cover
  overflow: hidden

  img
    width: 100%
    height: 100%
</style>
