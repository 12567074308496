<template>
  <div id="token-activation-details">
    <div class="token-icon">
      <Spinner v-if="isDataEmpty" height="150px" />
      <img v-else v-bind:src="tokenData.tokenIcon" />
    </div>
    <div class="token-data">
      <div class="data-row">
        <span>
          <strong>Name:</strong>
        </span>
        <span>
          <strong>{{ tokenData.tokenName }}</strong>
        </span>
      </div>
      <div class="data-row">
        <span> Amount: </span>
        <span>
          {{ tokenData.count }}
        </span>
      </div>
    </div>
    <div class="controls">
      <b-button
        variant="outline-primary"
        v-on:click="$emit('decline', 'Activation declined')"
      >
        Decline
      </b-button>
      <b-button variant="primary" v-on:click="activateToken"> Accept </b-button>
    </div>
  </div>
</template>

<script>
import Spinner from "../base/Spinner.vue";
export default {
  name: "TheTokenDetails.vue",
  components: { Spinner },
  props: {
    collectionUrl: {
      type: String,
      required: true,
    },
    supplierId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tokenData: {},
      isSpinnerVisible: false,
      errorMessages: {
        wrongQrCode:
          "Token data couldn't be read. Are you using correct QR code?",
        unknownError: "Unknown error occurred. Please try again later.",
        activationFailed: "Activation cannot be performed",
      },
    };
  },
  emits: ["accept", "decline"],
  computed: {
    executeUrl() {
      return this.collectionUrl + "/execute";
    },
    isDataEmpty() {
      return (
        this.tokenData &&
        Object.keys(this.tokenData).length === 0 &&
        Object.getPrototypeOf(this.tokenData) === Object.prototype
      );
    },
  },
  methods: {
    getTokenData() {
      this.axios
        .get(this.collectionUrl, {
          headers: { "Merchant-Id": this.supplierId },
        })
        .then((response) => {
          console.log(response);
          this.tokenData = response.data;
        })
        .catch((error) => {
          if (error.response && [404, 403].includes(error.response.status)) {
            const errorMessage =
              error?.response?.data?.detail ?? this.errorMessages.wrongQrCode;
            this.$emit("decline", errorMessage);
          } else if (
            error.message === "Network Error" ||
            (error.response && error.response.status >= 500)
          ) {
            this.$emit("decline", this.errorMessages.wrongQrCode);
          } else {
            this.$emit("decline", this.errorMessages.unknownError);
          }
        });
    },
    activateToken() {
      this.axios
        .post(
          this.executeUrl,
          {},
          {
            headers: { "Merchant-Id": this.supplierId },
          }
        )
        .then(() => {
          this.$emit("accept");
        })
        .catch((error) => {
          const errorMessage = [404, 403, 400].includes(error.response.status)
            ? error.response.data.detail
            : this.errorMessages.activationFailed;
          this.$emit("decline", errorMessage);
        });
    },
  },
  created() {
    this.getTokenData();
  },
};
</script>

<style lang="sass" scoped>
#token-activation-details
    width: 100%
    max-width: 250px

.token-icon
    height: 150px
    width: 150px

    img
      height: 100%
      width: 100%
      object-fit: cover

.token-data
    margin: 35px 0

.controls
    display: flex
    justify-content: space-around

.data-row
    display: flex
    justify-content: space-between
</style>
