<template>
  <div id="space-creation-form">
    <div class="form-container">
      <ApiForm
        v-on:success="$bvModal.show('modal-success')"
        v-bind="apiFormOptions"
      />
    </div>
    <b-modal
      id="modal-success"
      ok-only
      title="Success"
      v-on:hide="handleModalHidden"
    >
      <p class="my-4">Space has been created</p>
    </b-modal>
  </div>
</template>

<script>
import ApiForm from "@/components/base/ApiForm";
import {
  SPACE_CREATE_ROUTE,
  MERCHANT_LIST_CREATE,
} from "@/constants/relative_api_routes";
import storeAuthStatusGetters from "@/mixins/store_auth_status_getters";
import { SPACES } from "@/constants/page_names";
export default {
  name: "Spaces",
  components: { ApiForm },
  mixins: [storeAuthStatusGetters],
  data() {
    return {
      merchantList: [],
      url: this.appConfig.BACKEND_API_URL + MERCHANT_LIST_CREATE,
    };
  },
  methods: {
    handleModalHidden() {
      this.$router.push({ name: SPACES });
    },
    async getMerchants() {
      return await this.axios.get(this.url).then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          this.merchantList.push({
            value: response.data[i].id,
            text: response.data[i].public_display_name,
          });
        }
      });
    },
  },
  computed: {
    apiFormOptions() {
      return {
        formName: "space-create",
        targetUrl: `${this.appConfig.BACKEND_API_URL}${SPACE_CREATE_ROUTE}`,
        customFieldOptions: {
          icon: { label: "Banner" },
          suppliers: {
            multiple: true,
            choices: this.merchantList,
            default: [this.userId],
          },
        },
      };
    },
  },
  created() {
    this.getMerchants();
  },
};
</script>

<style scoped></style>
