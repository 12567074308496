<template>
  <div class="spinner-container">
    <div>
      <slot />
    </div>
    <img
      v-bind:style="spinnerImageStyle"
      src="@/assets/images/spinner.svg"
      alt="spinner"
    />
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    height: {
      type: String,
      required: false,
      default: "85px",
    },
  },
  computed: {
    spinnerImageStyle() {
      return { height: this.height };
    },
  },
};
</script>

<style scoped lang="sass">
.spinner-container
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  height: inherit
</style>
