<template>
  <div class="form-container">
    <BaseForm
      v-bind:field-data="fieldData"
      v-bind:model-data="modelData"
      v-bind:errors="errors"
      v-bind:form-name="formName"
      v-bind:conditional-fields="conditionalFields"
      v-on:submit="onFormSubmit"
    />
    <b-modal
      id="modal-warning"
      title="Warning"
      cancel-variant="outline-primary"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      v-on:ok="emitSubmit"
    >
      <div class="my-4">
        <p>
          If you don't define any conditions, the rule will apply to each and
          every one of your customers.
        </p>
        <p>Make sure this is what you want.</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BaseForm from "@/components/base/BaseForm";
import store_auth_status_getters from "@/mixins/store_auth_status_getters";
import {
  getConditionTypeLabel,
  PROMO_RULE_TYPES,
  PROMO_RULE_TYPE_LABELS,
} from "@/constants/promo_rules";
import * as FORM_FIELD_TYPES from "@/constants/form_field_types_identifiers";

export default {
  name: "ThePromoRuleForm",
  components: { BaseForm },
  mixins: [store_auth_status_getters],
  emits: ["submit"],
  props: {
    allowedOptions: {
      type: Object,
      required: true,
    },
    tokenChoices: {
      type: Array,
      default: () => {
        return [];
      },
      required: false,
    },
    errors: {
      type: Object,
      required: true,
    },
    formName: {
      type: String,
      default: "",
      required: false,
    },
    instance: {
      type: Object,
      default: () => {
        return {};
      },
      required: false,
    },
  },
  data() {
    return {
      modelData: { ...this.instance },
      conditionalFields: new Map([
        [
          ["end_date", "amount_type"],
          {
            promo_rule_type: (value) =>
              !!value && value !== PROMO_RULE_TYPES.TIME,
          },
        ],
        [
          ["return_token", "amount", "start_date", "conditions"],
          { promo_rule_type: (value) => !!value },
        ],
      ]),
      amountTypeChoices: {
        absolute: { value: "ABSOLUTE", text: "Absolute" },
        relative: { value: "RELATIVE", text: "Relative" },
      },
    };
  },
  computed: {
    conditionChoices() {
      const currentPromoRuleType = this.modelData["promo_rule_type"];
      return currentPromoRuleType
        ? this.allowedOptions[currentPromoRuleType].map((condition) => ({
            value: condition,
            text: getConditionTypeLabel(condition),
          }))
        : [];
    },

    typeChoices() {
      return Object.keys(this.allowedOptions).map((type) => ({
        value: type,
        text: PROMO_RULE_TYPE_LABELS[type],
      }));
    },

    fieldData() {
      return {
        name: {
          label: "Name",
          type: FORM_FIELD_TYPES.TEXT_FIELD,
          required: true,
        },
        promo_rule_type: {
          label: "Trigger",
          type: FORM_FIELD_TYPES.CHOICE_FIELD,
          required: true,
          default: PROMO_RULE_TYPES.PAYMENT,
          choices: this.typeChoices,
        },
        amount: {
          label: "Amount",
          type: FORM_FIELD_TYPES.NUMBER_FIELD,
          required: true,
          min: 0.01,
          step: 0.01,
        },
        amount_type: {
          label: "Amount type",
          type: FORM_FIELD_TYPES.CHOICE_FIELD,
          required: true,
          choices: Object.values(this.amountTypeChoices),
        },
        return_token: {
          label: "Return token",
          type: FORM_FIELD_TYPES.CHOICE_FIELD,
          choices: this.tokenChoices,
          required: true,
        },
        start_date: {
          label: "Starts on",
          type: FORM_FIELD_TYPES.DATE_FIELD,
          required: true,
        },
        end_date: {
          label: "Ends on",
          type: FORM_FIELD_TYPES.DATE_FIELD,
          required: true,
        },
        conditions: {
          label: "Conditions",
          type: FORM_FIELD_TYPES.PARAMETERIZED_SELECT,
          lookup_field: "condition_type",
          choices: this.conditionChoices,
          parameters: {
            value: {
              type: FORM_FIELD_TYPES.TEXT_FIELD,
              required: true,
            },
          },
          multiple: true,
          showLabels: false,
        },
      };
    },
  },
  methods: {
    clean(formData) {
      if (formData["promo_rule_type"] === PROMO_RULE_TYPES.TIME) {
        formData["end_date"] = formData["start_date"];
        formData["amount_type"] = this.amountTypeChoices.absolute.value;
      }
      return formData;
    },
    emitSubmit() {
      this.$emit("submit", this.clean(this.modelData));
    },
    onFormSubmit() {
      if (
        this.conditionChoices.length &&
        (!this.modelData.conditions || this.modelData.conditions.length === 0)
      ) {
        this.$bvModal.show("modal-warning");
      } else {
        this.emitSubmit();
      }
    },
  },
};
</script>

<style lang="sass">
.form-container
  max-width: 676px
  margin: auto
  padding-bottom: 50px
</style>
