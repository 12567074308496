<template>
  <div id="wallet">
    <h1>Wallet</h1>
    <div>
      <div id="wallet-header">
        <h2>
          <Placeholder v-bind:content="paymail" />
        </h2>
        <TheWalletSync
          v-on:wallet-synced="reloadWalletBalance"
          v-if="paymail"
        />
      </div>
      <div id="wallet-contents">
        <b-tabs content-class="mt-3" justified>
          <b-tab title="Balance" active lazy>
            <TheWalletBalanceList
              v-bind:table-height="tableHeight"
              v-bind:key="balanceListKey"
            />
          </b-tab>
          <b-tab title="History" lazy>
            <TheWalletHistoryList
              v-bind:table-height="tableHeight"
              v-bind:paymail="paymail"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Placeholder from "@/components/base/Placeholder";
import TheWalletBalanceList from "@/components/wallet/TheWalletBalanceList";
import TheWalletHistoryList from "@/components/wallet/TheWalletHistoryList";
import TheWalletSync from "@/components/wallet/TheWalletSync";
import { WALLET_ADDRESS_RETRIEVE_ROUTE } from "@/constants/relative_api_routes";

export default {
  name: "Wallet",
  components: {
    TheWalletBalanceList,
    TheWalletHistoryList,
    TheWalletSync,
    Placeholder,
  },
  data() {
    return {
      paymail: "",
      balanceListKey: 0,
    };
  },
  computed: {
    tableHeight() {
      return `min(50vh, calc(${(window.innerHeight * 0.5) / 713} * 100vh))`;
    },
  },
  methods: {
    getPaymail() {
      this.axios
        .get(this.appConfig.BACKEND_API_URL + WALLET_ADDRESS_RETRIEVE_ROUTE)
        .then((response) => {
          this.paymail = response.data.paymail;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    reloadWalletBalance() {
      // Force vue to reload balance list
      this.balanceListKey++;
    },
  },
  created() {
    this.getPaymail();
  },
};
</script>

<style lang="sass">
@import "src/assets/styles/base"

#wallet-header
  margin-bottom: 2.5rem
  margin-top: -73px
  display: flex
  flex-direction: row
  justify-content: center
  gap: 1rem

  h2
    color: $centi-blue

#wallet-contents
  width: min(600px, 100%)
  margin: auto

.table
  td
    vertical-align: middle

.token-icon
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  height: 50px
  width: 50px
  margin: auto
</style>
