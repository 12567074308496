<template>
  <div>
    <ThePromoRuleForm
      v-if="isFormReady"
      v-bind:allowed-options="allowedOptions"
      v-bind:token-choices="tokenChoices"
      v-bind:errors="errors"
      v-bind:instance="promoRule"
      form-name="promo-rule-details-form"
      v-on:submit="onSubmit($event)"
    />
    <b-modal
      id="modal-success"
      ok-only
      title="Success"
      v-on:hide="handleSuccessModalHidden"
    >
      <p class="my-4">Promotion rule has been edited</p>
    </b-modal>
  </div>
</template>

<script>
import ThePromoRuleForm from "@/components/promo_rules/ThePromoRuleForm";
import {
  PROMO_RULE_DETAILS_ROUTE,
  TOKENS_ROUTE,
} from "@/constants/relative_api_routes";
import { PROMO_RULE_CONDITIONS_CHOICES } from "@/constants/promo_rules";
import { PROMO_RULES } from "@/constants/page_names";
import store_auth_status_getters from "@/mixins/store_auth_status_getters";
import dataReformatter from "@/mixins/data_reformatter";

export default {
  name: "PromoRuleDetails",
  components: { ThePromoRuleForm },
  mixins: [dataReformatter, store_auth_status_getters],
  data() {
    return {
      promoRule: {},
      url:
        this.appConfig.BACKEND_API_URL +
        PROMO_RULE_DETAILS_ROUTE(this.$route.params.id),
      allowedOptions: PROMO_RULE_CONDITIONS_CHOICES,
      tokenChoices: [],
      errors: {},
      isFormReady: false,
    };
  },

  methods: {
    getPromoRule() {
      this.axios.get(this.url).then((response) => {
        this.promoRule = response.data;
        this.promoRule["start_date"] = this.parseDatetimeToDateOnly(
          this.promoRule["start_date"]
        );
        this.promoRule["end_date"] = this.parseDatetimeToDateOnly(
          this.promoRule["end_date"]
        );
        this.isFormReady = true;
      });
    },

    onSubmit(payload) {
      this.axios
        .put(this.url, payload)
        .then(() => this.$bvModal.show("modal-success"))
        .catch((error) => {
          if (error.response.status >= 400 && error.response.status < 500) {
            this.errors = error.response.data;
          } else {
            this.errors = { _internal: error.response.statusText };
          }
        });
    },

    fetchTokens() {
      this.axios
        .get(this.appConfig.BACKEND_API_URL + TOKENS_ROUTE(this.userId))
        .then((response) => {
          this.tokenChoices = response.data.map((token) => {
            return {
              value: token.id,
              text: token.name,
            };
          });
        });
    },

    handleSuccessModalHidden() {
      this.$router.push({ name: PROMO_RULES });
    },
  },

  created() {
    this.getPromoRule();
    this.fetchTokens();
  },
};
</script>
