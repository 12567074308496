<template>
  <div id="token-creation-form">
    <div class="form-container">
      <ApiForm
        v-on:success="$bvModal.show('modal-success')"
        v-bind="apiFormOptions"
      />
    </div>
    <b-modal
      id="modal-success"
      ok-only
      title="Success"
      v-on:hide="handleModalHidden"
    >
      <p class="my-4">Token has been created</p>
    </b-modal>
  </div>
</template>

<script>
import ApiForm from "@/components/base/ApiForm";
import { TOKEN_CREATE_ROUTE } from "@/constants/relative_api_routes";
import { TOKENS } from "@/constants/page_names";
export default {
  name: "TokenCreate",
  components: { ApiForm },
  data() {
    return {
      apiFormOptions: {
        formName: "token-create",
        targetUrl: `${this.appConfig.BACKEND_API_URL}${TOKEN_CREATE_ROUTE}`,
        hiddenFields: ["contract_txid", "token_address", "issuance_txid"],
        customFieldOptions: {
          description: {
            type: "textarea",
          },
          terms: {
            type: "textarea",
          },
          is_ft: {
            type: "checkbox",
            label: "Does it serve as means of payment?",
          },
        },
      },
    };
  },
  methods: {
    handleModalHidden() {
      this.$router.push({ name: TOKENS });
    },
  },
};
</script>

<style scoped></style>
