<template>
  <div id="wallets-list">
    <div id="top-controls">
      <b-form-checkbox
        v-model="allSelected"
        name="toggle-all"
        v-bind:indeterminate="indeterminate"
        v-on:change="toggleAll"
      >
        {{ allSelected ? "deselect all" : "select all" }}
      </b-form-checkbox>
      <b-button variant="primary" v-b-modal.modal-generate-wallets>
        Generate new wallets
      </b-button>
    </div>
    <b-table
      bordered
      hover
      v-bind:items="wallets"
      v-bind:fields="fields"
      headVariant="light"
    >
      <template v-slot:cell(select)="row">
        <b-form-checkbox v-model="row.item.selected" />
      </template>
    </b-table>
    <Spinner v-if="isFetchingWallets">
      Your wallets are being processed...
    </Spinner>
    <div id="bottom-controls">
      <b-button
        variant="primary"
        v-on:click="downloadCSV"
        v-bind:disabled="selection.length === 0"
      >
        Export to CSV
      </b-button>
      <b-button
        variant="primary"
        v-b-modal.modal-top-up
        v-bind:disabled="selection.length === 0"
      >
        Top-up
      </b-button>
      <b-button variant="primary" v-b-modal.modal-top-up-csv>
        Top-up from CSV
      </b-button>
    </div>
    <b-modal
      id="modal-generate-wallets"
      title="Generate new wallets"
      hide-footer
      lazy
    >
      <GenerationForm
        name="wallets"
        v-bind:target-url="walletsBackendUrl"
        max="1000"
        v-on:success="onWalletsGeneration"
      />
    </b-modal>
    <b-modal id="modal-top-up" title="Top-up wallets" hide-footer lazy>
      <TheTopUpForm
        v-bind:wallet-addresses="selection.map((wallet) => wallet.address)"
        v-on:submit="handleTopUp($event)"
      />
    </b-modal>
    <b-modal
      id="modal-top-up-csv"
      title="Top-up wallets from CSV"
      hide-footer
      lazy
    >
      <TheTopUpCSVForm v-on:submit="handleTopUpFromCSV($event)" />
    </b-modal>
  </div>
</template>

<script>
import data_reformatter from "@/mixins/data_reformatter";
import TheTopUpForm from "@/components/wallets/TheTopUpForm";
import TheTopUpCSVForm from "@/components/wallets/TheTopUpCSVForm";
import GenerationForm from "@/components/base/GenerationForm";
import Spinner from "@/components/base/Spinner";
import { WALLETS_ROUTE } from "@/constants/relative_api_routes";

export default {
  name: "TheWalletList",
  components: {
    TheTopUpCSVForm,
    TheTopUpForm,
    GenerationForm,
    Spinner,
  },
  mixins: [data_reformatter],
  props: {
    spaceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      wallets: [],
      fields: ["select", "id", "name", "address", "balance", "registered_at"],
      allSelected: false,
      indeterminate: false,
      walletsLimit: 1000,
      walletListCSVFilename: "wallets",
      isFetchingWallets: true,
    };
  },
  computed: {
    selection() {
      let selection = [];
      for (let wallet of this.wallets) {
        if (wallet.selected) {
          selection.push({ id: wallet.id, address: wallet.address });
        }
      }
      return selection;
    },
    walletsBackendUrl() {
      return this.appConfig.BACKEND_API_URL + WALLETS_ROUTE(this.spaceId);
    },
  },
  watch: {
    selection(newValue) {
      this.indeterminate =
        newValue.length > 0 && newValue.length < this.wallets.length;
      this.allSelected = newValue.length === this.wallets.length;
    },
  },
  methods: {
    toggleAll(checked) {
      for (let wallet of this.wallets) {
        wallet.selected = checked;
      }
    },
    onWalletsGeneration() {
      this.fetchWallets().then(() =>
        this.$bvModal.hide("modal-generate-wallets")
      );
    },
    fetchWallets() {
      return this.fetchWalletsFromApi().then((data) => {
        this.wallets = this.parseData(data);
        this.isFetchingWallets = false;
      });
    },
    parseData(wallets_data) {
      for (let wallet of wallets_data) {
        wallet.registered_at = this.parseDatetime(wallet.registered_at);
        wallet.selected = false;
        wallet.address = wallet.wallet_address;
        wallet.balance = this.parseBalanceData(wallet.balance);
      }
      return wallets_data;
    },
    parseBalanceData(balanceData) {
      let balance = "";
      for (let partialBalance of balanceData) {
        balance += `${partialBalance["_currency"]}: ${partialBalance["total_balance"]}, `;
      }
      return balance.slice(0, -2);
    },
    async fetchWalletsFromApi() {
      let response = await this.axios.get(this.walletsBackendUrl);
      return response.data;
    },
    async fetchWalletDataCSV() {
      const headers = Object.assign({}, this.axios.defaults.headers.common, {
        Accept: "text/csv",
      });
      const params = {
        id: this.selection.map((wallet) => wallet.id).toString(),
      };
      const response = await this.axios.get(this.walletsBackendUrl, {
        params,
        headers,
      });
      return response.data;
    },
    createAndDownloadCSVFile(data) {
      let hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(data);
      hiddenElement.target = "_blank";
      hiddenElement.download = `${this.walletListCSVFilename}.csv`;
      hiddenElement.click();
    },
    async downloadCSV() {
      const csvData = await this.fetchWalletDataCSV();
      this.createAndDownloadCSVFile(csvData);
    },
    // For Top-up functionality mock only
    handleTopUpFromCSV(requestData) {
      this.$bvModal.hide("modal-top-up-csv");
      for (let topUp of requestData) {
        let targetWallet = this.wallets.find((wallet) => {
          return wallet.address === topUp.address;
        });
        if (!targetWallet) {
          targetWallet = {
            id:
              this.wallets.length > 0
                ? this.wallets[0].id + this.wallets.length
                : 1,
            address: topUp.address,
            balance: "",
            registered_at: Intl.DateTimeFormat("en-GB", {
              timeZone: "UTC",
              dateStyle: "full",
              timeStyle: "long",
            }).format(new Date()),
          };
          this.wallets.push(targetWallet);
        }
        this.updateTopUp(targetWallet, topUp);
      }
    },
    handleTopUp(topUpData) {
      this.$bvModal.hide("modal-top-up");
      for (let wallet of this.wallets) {
        if (wallet.selected) {
          this.updateTopUp(wallet, topUpData);
        }
      }
    },
    updateTopUp(wallet, topUpData) {
      wallet.balance += `${topUpData.currency}: ${topUpData.amount}, `;
    },
  },
  created() {
    this.fetchWallets();
  },
};
</script>

<style scoped lang="sass">
#wallets-list
  margin: 0 30px 30px

#top-controls
  display: flex
  justify-content: space-between
  align-items: center
  padding-bottom: 15px

#bottom-controls
  margin-top: 30px

  button
    width: 150px
    margin: 0 10px
</style>
