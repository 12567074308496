export const PROMO_RULE_TYPES = {
  PAYMENT: "PAYMENT",
  TOPUP: "TOPUP",
  COLLECTION: "COLLECTION",
  TIME: "TIME",
};

export const PROMO_RULE_TYPE_LABELS = {
  [PROMO_RULE_TYPES.PAYMENT]: "Payment",
  [PROMO_RULE_TYPES.TOPUP]: "Top-up",
  [PROMO_RULE_TYPES.COLLECTION]: "Collection",
  [PROMO_RULE_TYPES.TIME]: "Time",
};

export const PROMO_RULE_CONDITIONS = {
  ONLY_N_TIMES: "ONLY_N_TIMES",
  PAYMENT_GTE_N: "PAYMENT_GTE_N",
  EVERY_N_PAYMENTS: "EVERY_N_PAYMENTS",
  TOPUP_GTE_N: "TOPUP_GTE_N",
  EVERY_N_TOPUPS: "EVERY_N_TOPUPS",
  TOKEN_ID: "TOKEN_ID",
  REFERRAL_CODE: "REFERRAL_CODE",
};

export const PROMO_RULE_CONDITIONS_CHOICES = {
  [PROMO_RULE_TYPES.TIME]: [],
  [PROMO_RULE_TYPES.PAYMENT]: [
    PROMO_RULE_CONDITIONS.ONLY_N_TIMES,
    PROMO_RULE_CONDITIONS.PAYMENT_GTE_N,
    PROMO_RULE_CONDITIONS.EVERY_N_PAYMENTS,
  ],
  [PROMO_RULE_TYPES.COLLECTION]: [
    PROMO_RULE_CONDITIONS.ONLY_N_TIMES,
    PROMO_RULE_CONDITIONS.TOKEN_ID,
  ],
};

export const GLOBAL_PROMO_RULE_CONDITIONS_CHOICES = {
  [PROMO_RULE_TYPES.TIME]: [PROMO_RULE_CONDITIONS.REFERRAL_CODE],
  [PROMO_RULE_TYPES.PAYMENT]: [
    PROMO_RULE_CONDITIONS.ONLY_N_TIMES,
    PROMO_RULE_CONDITIONS.PAYMENT_GTE_N,
    PROMO_RULE_CONDITIONS.EVERY_N_PAYMENTS,
    PROMO_RULE_CONDITIONS.REFERRAL_CODE,
  ],
  [PROMO_RULE_TYPES.TOPUP]: [
    PROMO_RULE_CONDITIONS.ONLY_N_TIMES,
    PROMO_RULE_CONDITIONS.TOPUP_GTE_N,
    PROMO_RULE_CONDITIONS.EVERY_N_TOPUPS,
    PROMO_RULE_CONDITIONS.REFERRAL_CODE,
  ],
};

const PROMO_RULE_CONDITION_NAME_GENERATORS = {
  [PROMO_RULE_CONDITIONS.ONLY_N_TIMES]: (count) =>
    `Takes effect up to ${count} time(s) for single customer`,

  [PROMO_RULE_CONDITIONS.PAYMENT_GTE_N]: (count) =>
    `If customer's payment is ${count} or more`,

  [PROMO_RULE_CONDITIONS.EVERY_N_PAYMENTS]: (count) =>
    `Takes effect every ${count} payment(s) in a row from single customer`,

  [PROMO_RULE_CONDITIONS.TOPUP_GTE_N]: (count) =>
    `If customer's top-up is ${count} or more`,

  [PROMO_RULE_CONDITIONS.EVERY_N_TOPUPS]: (count) =>
    `Takes effect every ${count} top-up(s) in a row from single customer`,
  [PROMO_RULE_CONDITIONS.TOKEN_ID]: (id) =>
    `If customer used token with id ${id}`,
  [PROMO_RULE_CONDITIONS.REFERRAL_CODE]: (code) =>
    `If customer used code ${code}`,
};

const PROMO_RULE_CONDITION_LABELS = {
  [PROMO_RULE_CONDITIONS.ONLY_N_TIMES]: "Take effect only X times per customer",

  [PROMO_RULE_CONDITIONS.EVERY_N_PAYMENTS]:
    "Take effect every X payments in a row from single customer",

  [PROMO_RULE_CONDITIONS.EVERY_N_TOPUPS]:
    "Take effect every X top-ups in a row from single customer",
  [PROMO_RULE_CONDITIONS.TOKEN_ID]: "If customer used token with id X",
  [PROMO_RULE_CONDITIONS.REFERRAL_CODE]: "If customer used referral code",
};

export function getPromoRuleConditionName(condition) {
  return PROMO_RULE_CONDITION_NAME_GENERATORS[condition["condition_type"]](
    condition["value"]
  );
}

export function getConditionTypeLabel(conditionType) {
  return (
    PROMO_RULE_CONDITION_LABELS[conditionType] ||
    PROMO_RULE_CONDITION_NAME_GENERATORS[conditionType]("X")
  );
}
