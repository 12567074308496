<template>
  <div>
    <b-form-group v-for="(item, index) in selectedItems" v-bind:key="index">
      <b-input-group
        class="form-group"
        v-bind:class="{
          'no-margin': !isSelectVisible && isLastSelectedItem(index),
        }"
      >
        <b-form-select
          v-model="item[lookupField]"
          v-bind:options="options"
          v-bind:disabled="multiple"
          v-on:change="setProperty(index, lookupField, $event)"
        />
        <b-input-group-append>
          <template v-for="(inputOptions, parameter) in parameters">
            <b-input-group-text
              v-if="showLabels"
              v-bind:key="index + parameter"
            >
              {{ getLabel(parameter) }}:
            </b-input-group-text>
            <b-form-input
              v-bind:value="item[parameter] || inputOptions.default"
              v-bind:type="inputOptions.type"
              v-on:change="setProperty(index, parameter, $event)"
              v-on:input="setProperty(index, parameter, $event)"
              v-bind:required="inputOptions.required"
              v-bind:state="getState(index, parameter)"
              v-bind:placeholder="inputOptions['help_text']"
              v-bind:step="inputOptions.step"
              v-bind:min="inputOptions.min"
              v-bind:max="inputOptions.max"
              v-bind:key="parameter"
            />
          </template>
          <b-button variant="danger" v-on:click="removeItem(index)">
            <b-icon class="icon-button" icon="x"
          /></b-button>
        </b-input-group-append>
      </b-input-group>
      <template v-if="hasErrors(index)">
        <template v-for="parameter in Object.keys(parameters)">
          <div
            v-if="localErrors[index][parameter]"
            class="invalid-feedback d-block"
            role="alert"
            v-bind:key="parameter"
          >
            {{ generateErrorMessage(index, parameter) }}
          </div>
        </template>
        <template v-if="nonFieldErrors[index].length > 0">
          <div
            v-for="(message, id) in nonFieldErrors[index]"
            class="invalid-feedback d-block"
            role="alert"
            v-bind:key="id + '-general-error'"
          >
            {{ message }}
          </div>
        </template>
      </template>
    </b-form-group>
    <b-form-select
      v-if="isSelectVisible"
      v-bind:options="filteredOptions"
      v-bind:required="required && selectedItems.length === 0"
      v-bind:key="selectedItems.length"
      v-on:change="updateItems($event)"
    />
  </div>
</template>

<script>
export default {
  name: "ParameterizedSelect",
  props: {
    value: {
      type: [Array, Object],
      required: false,
    },
    lookupField: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    parameters: {
      type: Object,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLabels: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    errors: {
      type: Array,
      required: false,
      null: true,
    },
  },
  data() {
    return {
      selectedItems: [],
      localErrors: this.errors,
    };
  },
  computed: {
    filteredOptions() {
      const expiredChoices = this.selectedItems.map(
        (item) => item[this.lookupField]
      );
      return this.options.filter(
        (option) => !expiredChoices.includes(option.value)
      );
    },
    isSelectVisible() {
      return (
        (this.filteredOptions.length > 0 && this.multiple) ||
        this.selectedItems.length === 0
      );
    },
    output() {
      return this.multiple ? this.selectedItems : this.selectedItems[0];
    },
    nonFieldErrors() {
      return this.localErrors.map((errorData) => {
        let nonFieldMessages = [];
        for (let fieldName in errorData) {
          if (!Object.keys(this.parameters).includes(fieldName)) {
            nonFieldMessages.push(...errorData[fieldName]);
          }
        }
        return nonFieldMessages;
      });
    },
  },
  watch: {
    value: {
      handler: function () {
        this.selectedItems = this.getInitialSelection();
      },
      immediate: true,
    },
    errors(value) {
      this.localErrors = value;
    },
  },
  methods: {
    isLastSelectedItem(index) {
      return index === this.selectedItems.length - 1;
    },
    setProperty(index, property, value) {
      this.selectedItems[index] = Object.assign(this.selectedItems[index], {
        [property]: value,
      });
      this.$emit("input", this.output);
    },
    removeItem(itemIndex) {
      this.selectedItems.splice(itemIndex, 1);
      if (this.hasErrors(itemIndex)) {
        this.localErrors.splice(itemIndex, 1);
      }
      this.$emit("input", this.output);
    },
    updateItems(choice) {
      const index = this.selectedItems.length;
      this.selectedItems.push({ [this.lookupField]: choice });
      Object.keys(this.parameters).forEach((parameter) => {
        if (this.parameters[parameter].default !== undefined) {
          this.selectedItems[index][parameter] =
            this.parameters[parameter].default;
        }
      });
      this.$emit("input", this.output);
    },
    hasErrors(index) {
      return this.localErrors && index < this.localErrors.length;
    },
    getInitialSelection() {
      if (!this.value) {
        return [];
      }
      if (this.multiple) {
        return this.value;
      }
      return Object.keys(this.value).length > 0 ? [this.value] : [];
    },
    getLabel(parameter) {
      return this.parameters[parameter].label || parameter;
    },
    getState(index, parameter) {
      if (this.hasErrors(index)) {
        if (Object.keys(this.localErrors[index]).includes(parameter)) {
          return false;
        }
      }
      return null;
    },
    generateErrorMessage(index, parameter) {
      return `${this.getLabel(parameter)}: ${
        this.localErrors[index][parameter][0]
      }`;
    },
  },
};
</script>

<style scoped lang="sass">
.form-group
  margin-bottom: 0

.input-group
  margin-bottom: 1rem

.invalid-feedback
  margin: -1rem 0 1rem

.no-margin
  margin-bottom: 0
</style>
