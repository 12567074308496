<template>
  <div id="app">
    <TheNavbar v-if="isNavbarAllowed" />
    <router-view />
  </div>
</template>

<script>
import TheNavbar from "@/components/app/TheNavbar";
export default {
  components: { TheNavbar },
  data() {
    return {
      standalonePagesDirectories: ["cashier", "scanner"],
    };
  },
  computed: {
    isNavbarAllowed() {
      return !this.$route.matched.some((record) =>
        record.path
          .split("/")
          .some((urlChunk) =>
            this.standalonePagesDirectories.some(
              (directory) => directory === urlChunk
            )
          )
      );
    },
  },
};
</script>

<style lang="sass">
@import "src/assets/styles/base"

#app, .modal-dialog
  font-family: "Segoe UI", sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50
  padding: 56px 30px 20px

  h1
    text-align: left
    color: $centi-blue
    font-weight: bold
    margin: 30px 0

  .page-header-aligned
    margin-top: -73px

  .form-container
    max-width: 676px
    margin: auto
    padding-bottom: 50px

  .form-group
    text-align: left
    align-items: center

    .center-alignment
      display: flex
      align-items: center

    .form-input-appendix
      padding: 0
      color: #3a9ff3

  .nav-tabs
    border-bottom-color: $centi-blue
    margin-bottom: 31px

    .nav-link
      &.active, &:hover
        border-color: $centi-blue $centi-blue transparent

  table
    border: 0

    .card
      background-color: $centi-light-gray

    th, td
      border: 1px solid white
      background-color: $centi-light-blue
      border-left: 0
      border-right: 0

    th
      background-color: $centi-light-blue
      border-bottom: 2px solid white

  .icon-button
    transition: opacity 0.25s

    &:hover
      opacity: 0.33
</style>
