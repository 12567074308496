<template>
  <div id="item-list">
    <div id="top-controls">
      <b-button variant="primary" v-b-modal.modal-generate-items>
        Generate new {{ itemName }}s
      </b-button>
    </div>
    <b-table
      id="item-table"
      bordered
      hover
      v-bind:items="items"
      v-bind:fields="fields"
      headVariant="light"
    >
      <template v-slot:cell(delete)="data">
        <IconButton
          class="delete-button"
          variant="danger"
          icon="trash"
          size="sm"
          v-b-modal.modal-delete-item
          v-on:click="selectedItem = data.item"
        />
      </template>
      <template v-slot:cell(url)="data">
        <span class="item-url">
          {{ data.item.url }}
          <IconButton
            class="copy-button"
            variant="primary"
            icon="clipboard"
            size="sm"
            v-on:click="copyUrlToClipboard(data.item.url)"
          />
        </span>
      </template>
      <template v-slot:cell(name)="data">
        <EditableTableCell
          label="name"
          v-bind:value="data.value"
          v-on:input="updateName(data.item.id, $event)"
          v-bind:identifier="data.item.id"
          v-bind:update-url="updateUrl(data.item.id)"
        />
      </template>
    </b-table>
    <b-modal
      id="modal-generate-items"
      v-bind:title="`Generate new ${itemName}s`"
      hide-footer
      lazy
    >
      <GenerationForm
        name="items"
        v-bind:target-url="listUrl"
        max="100"
        v-on:success="onItemsGeneration"
      />
    </b-modal>
    <b-modal
      id="modal-delete-item"
      v-bind:title="`Delete ${itemName}`"
      cancel-variant="outline-primary"
      v-on:ok="deleteItem"
    >
      Are you sure you want to delete {{ itemName }} {{ selectedItem.name }}?
    </b-modal>
  </div>
</template>

<script>
import GenerationForm from "@/components/base/GenerationForm";
import EditableTableCell from "@/components/base/EditableTableCell";
import IconButton from "@/components/base/IconButton";

export default {
  name: "TheAccessUrlManager",
  components: { IconButton, EditableTableCell, GenerationForm },
  props: {
    listUrl: {
      type: String,
      required: true,
    },
    updateUrl: {
      type: Function,
      required: true,
    },
    urlGenerator: {
      type: Function,
      required: true,
    },
    itemName: {
      type: String,
      required: false,
      default: "item",
    },
  },
  data() {
    return {
      items: [],
      fields: ["name", "url", "delete"],
      selectedItem: {},
    };
  },
  methods: {
    getItems() {
      return this.axios
        .get(this.listUrl)
        .then((response) => {
          let items = response.data;
          items.forEach((item) => (item.url = this.urlGenerator(item.id)));
          this.items = items;
        })
        .catch((error) => console.error(error));
    },
    copyUrlToClipboard(url) {
      return navigator.clipboard
        .writeText(url)
        .then(() =>
          this.$bvToast.toast("URL has been copied to clipboard", {
            autoHideDelay: 5000,
            variant: "info",
            toaster: "b-toaster-top-center",
          })
        )
        .catch((error) => console.log(error));
    },
    deleteItem() {
      this.axios
        .delete(this.updateUrl(this.selectedItem.id))
        .then(() => this.getItems())
        .catch((error) => console.error(error));
    },
    onItemsGeneration() {
      this.getItems().then(() => this.$bvModal.hide("modal-generate-items"));
    },
    updateName(itemId, newName) {
      this.items.forEach((item) => {
        if (item.id === itemId) {
          item.name = newName;
        }
      });
    },
  },
  created() {
    this.getItems();
  },
};
</script>

<style scoped lang="sass">
#item-list
  margin: 0 30px 30px

#item-table
  width: auto
  margin: auto

#top-controls
  padding-bottom: 15px

.item-url
  display: flex
  align-items: center
  justify-content: flex-end

.copy-button
  margin-left: 0.5em

.delete-button
  margin: auto
</style>
