<template>
  <div id="cashiers">
    <h1>Cashiers</h1>
    <TheSpaceSelector v-slot="slotProps">
      <TheCashierList
        v-bind:space-id="slotProps.spaceId"
        v-bind:key="slotProps.spaceId"
      />
    </TheSpaceSelector>
  </div>
</template>

<script>
import storeAuthStatusGetters from "@/mixins/store_auth_status_getters";
import TheCashierList from "@/components/cashiers/TheCashierList";
import TheSpaceSelector from "@/components/base/TheSpaceSelector";

export default {
  name: "Cashiers",
  mixins: [storeAuthStatusGetters],
  components: { TheCashierList, TheSpaceSelector },
};
</script>
