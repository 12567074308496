import { LOGIN_ACTION, LOGOUT_ACTION } from "@/constants/store_actions";
import { HOME_PAGE } from "@/constants/page_names";

export default {
  data() {
    return {
      storeNamespace: "",
    };
  },
  methods: {
    getAuthCredentials() {
      return null;
    },
    onAuthSuccess() {},
    authenticate() {
      const credentials = this.getAuthCredentials();
      if (!credentials) {
        this.$router.replace({ name: HOME_PAGE });
      } else {
        this.$store
          .dispatch(`${this.storeNamespace}/${LOGIN_ACTION}`, credentials)
          .then(() => {
            this.onAuthSuccess();
          })
          .catch((error) => {
            console.error(error);
            this.$router.replace({ name: HOME_PAGE });
          });
      }
    },
  },
  created() {
    this.authenticate();
  },
  destroyed() {
    delete this.$store.dispatch(`${this.storeNamespace}/${LOGOUT_ACTION}`);
  },
};
