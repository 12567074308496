<template>
  <div>
    <Spinner height="inherit" v-if="isInstallationPending">
      <h1 id="installation-pending-message">
        Centi Merchant installation is in progress
      </h1>
    </Spinner>
    <div id="installation-success-message" v-if="isInstallationSuccess">
      <h1>Centi Merchant has been successfully installed!</h1>
      <h3>
        You can now use Centi Merchant with Wallee to process your payments.
      </h3>
      <h5>You will now be redirected to the home page shortly.</h5>
    </div>
    <div id="installation-failed-message" v-if="isInstallationFailed">
      <h1>Something went wrong! :(</h1>
      <h3>
        We have encountered an error during installation. Please try again
        later.
      </h3>
      <h3>If the error persists, contact our administrators</h3>
    </div>
  </div>
</template>

<script>
import { WALLEE_INSTALLATION_ROUTE } from "@/constants/relative_integrator_app_routes";
import Spinner from "@/components/base/Spinner";
import { SIGN_IN } from "@/constants/page_names";

export default {
  name: "WalleeInstaller",
  components: { Spinner },
  data() {
    return {
      installationStates: {
        pending: "PENDING",
        success: "SUCCESS",
        failed: "FAILED",
      },
      currentState: null,
      target_url: this.appConfig.BACKEND_TOOLS_URL + WALLEE_INSTALLATION_ROUTE,
    };
  },
  computed: {
    isInstallationPending() {
      return this.currentState === this.installationStates.pending;
    },
    isInstallationSuccess() {
      return this.currentState === this.installationStates.success;
    },
    isInstallationFailed() {
      return this.currentState === this.installationStates.failed;
    },
  },
  methods: {
    performInstallation() {
      this.currentState = this.installationStates.pending;
      this.axios
        .post(this.target_url, this.$route.query)
        .then(() => this.finalizeInstallation())
        .catch(() => (this.currentState = this.installationStates.failed));
    },
    finalizeInstallation() {
      this.currentState = this.installationStates.success;
      setTimeout(
        (router = this.$router) => router.push({ name: SIGN_IN }),
        5000
      );
    },
  },
  created() {
    this.performInstallation();
  },
};
</script>

<style scoped lang="sass">
#installation-success-message
  h5
    margin-top: 50px
</style>
