import ErrorDisplay from "@/components/base/ErrorDisplay";
import GenericFormField from "@/components/base/GenericFormField";

export default {
  components: { ErrorDisplay, GenericFormField },
  emits: ["submit"],
  props: {
    fieldData: {
      type: Object,
      required: true,
    },
    modelData: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    formName: {
      type: String,
      default: "",
      required: false,
    },
    conditionalFields: {
      type: Map,
      default: () => {
        return new Map();
      },
      required: false,
    },
  },
  computed: {
    isFormFilled() {
      for (let fieldName in this.fieldData) {
        if (
          this.fieldData[fieldName].required &&
          this.isVisible(fieldName) &&
          !this.modelData[fieldName]
        ) {
          return false;
        }
      }
      return true;
    },
    nonFieldErrors() {
      let nonFieldErrors = [];
      for (let label in this.errors) {
        if (!(label in this.fieldData)) {
          nonFieldErrors = nonFieldErrors.concat(this.errors[label]);
        }
      }
      return nonFieldErrors;
    },
  },
  methods: {
    onSubmit() {
      this.$emit("submit");
    },
    isVisible(fieldName) {
      for (let [fields, conditions] of this.conditionalFields) {
        if (fields.includes(fieldName)) {
          for (let [relatedField, condition] of Object.entries(conditions)) {
            if (!condition(this.modelData[relatedField])) return false;
          }
        }
      }
      return true;
    },
    getError(fieldName) {
      return !this.errors[fieldName] ? null : this.errors[fieldName];
    },
    getState(fieldName) {
      return fieldName in this.errors ? false : null;
    },
  },
};
