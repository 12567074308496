<template>
  <div>
    <div class="dashboard-section" id="dashboard-filters">
      <div>
        <h1>Filters</h1>
        <div id="filter-container">
          <SimpleFilter
            class="filter-tile"
            v-model="ownershipSetting"
            label="Ownership"
            v-bind:options="Object.values(ownershipValues)"
            hide-delete
          />
          <TextBasedFilter
            v-for="(filterData, index) in filters"
            v-bind:key="index"
            class="filter-tile"
            v-model="filterData.filter"
            v-bind:label="filterData.field.label"
            v-bind:filterModes="filterModes[filterData.type]"
            v-on:delete="deleteFilter(filterData)"
          />
          <div id="add-filter-button-container" class="filter-tile">
            <b-button
              variant="primary"
              v-on:click="$bvModal.show('filter-creation-modal')"
            >
              Add +
            </b-button>
          </div>
        </div>
        <b-button variant="primary" v-on:click="fetchDataFromApi">
          Apply
        </b-button>
      </div>
      <div>
        <h1>Visibility</h1>
        <div id="visibility-settings">
          <b-form-checkbox
            v-for="(fieldData, fieldName) in fields"
            v-bind:key="fieldName"
            v-bind:id="`${fieldData.key.replaceAll(
              '_',
              '-'
            )}-visibility-checkbox`"
            v-model="visibility[fieldData.key]"
            v-bind:name="`${fieldData.key.replaceAll(
              '_',
              '-'
            )}-visibility-checkbox`"
          >
            {{ fieldData.label }}
          </b-form-checkbox>
        </div>
      </div>
      <div>
        <b-form-checkbox
          id="chart-inclusion-checkbox"
          v-model="includeCharts"
          name="chart-inclusion-checkbox"
        >
          Include charts
        </b-form-checkbox>
        <div>
          <b-button variant="primary">Generate report</b-button>
          <b-button variant="primary">Export to CSV</b-button>
        </div>
      </div>
    </div>
    <div class="dashboard-section" id="dashboard-table">
      <h1>Total income: {{ totalIncome }} CHF</h1>
      <div class="table-container">
        <b-table
          v-bind:items="payments"
          v-bind:fields="visibleFields"
          v-bind:current-page="tableOptions.currentPage"
          v-bind:perPage="tableOptions.perPage"
          v-bind:sortBy="tableOptions.sortBy"
          v-bind:sortDesc="tableOptions.sortDesc"
        />
      </div>
      <b-pagination
        v-model="tableOptions.currentPage"
        v-bind:total-rows="totalRows"
        v-bind:per-page="tableOptions.perPage"
      />
    </div>
    <div class="dashboard-section" id="dashboard-charts" v-if="payments.length">
      <apexchart
        width="500"
        type="pie"
        v-bind:options="{ labels: Object.keys(pieChartData) }"
        v-bind:series="Object.values(pieChartData)"
      />
      <apexchart
        width="500"
        type="line"
        :options="{ xaxis: { type: 'datetime' } }"
        :series="lineChartData"
      />
    </div>
    <b-modal id="filter-creation-modal" v-on:ok="createFilter">
      <b-form-group
        id="field-select"
        label="Field"
        label-for="field-select-input"
      >
        <b-form-select
          id="field-select-input"
          v-model="selectedField"
          v-bind:options="fieldSelectOptions"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import SimpleFilter from "@/components/dashboard/SimpleFilter.vue";
import TextBasedFilter from "@/components/dashboard/TextBasedFilter.vue";

export default {
  name: "Dashboard",
  components: { SimpleFilter, TextBasedFilter },
  data() {
    const fieldTypes = {
      text: 0,
      date: 1,
      number: 2,
      custom: 3,
    };
    return {
      payments: [],
      fieldTypes,
      fields: {
        base_currency: {
          key: "base_currency",
          label: "Base Currency",
          sortable: true,
          type: fieldTypes.text,
        },
        amount: {
          key: "amount",
          label: "Amount",
          sortable: true,
          type: fieldTypes.number,
        },
        currency: {
          key: "currency",
          label: "Accepted Token/Currency",
          sortable: true,
          type: fieldTypes.text,
        },
        ratio_to_base_currency: {
          key: "ratio_to_base_currency",
          label: "Ratio to Base Currency",
          sortable: true,
          type: fieldTypes.number,
        },
        space_id: {
          key: "space_id",
          label: "Space ID",
          sortable: true,
          type: fieldTypes.text,
        },
        owned_space: {
          key: "owned_space",
          label: "Owned Space",
          sortable: true,
          type: fieldTypes.custom,
        },
        merchant: {
          key: "merchant",
          label: "Merchant",
          sortable: true,
          type: fieldTypes.text,
        },
        pos_id: {
          key: "pos_id",
          label: "PosID",
          sortable: true,
          type: fieldTypes.text,
        },
        basket: {
          key: "basket",
          label: "Basket",
          sortable: true,
          type: fieldTypes.text,
        },
        offers: {
          key: "offers",
          label: "Offers",
          sortable: true,
          type: fieldTypes.text,
        },
        date: {
          key: "date",
          label: "Date/Time",
          sortable: true,
          type: fieldTypes.date,
        },
      },
      visibility: {
        base_currency: true,
        amount: true,
        currency: true,
        ratio_to_base_currency: true,
        space_id: true,
        owned_space: false,
        merchant: true,
        pos_id: true,
        basket: false,
        offers: false,
        date: true,
      },
      filterModes: {
        [fieldTypes.text]: [
          { value: "include", text: "INCLUDE" },
          { value: "exclude", text: "EXCLUDE" },
          { value: "search", text: "SEARCH" },
        ],
        [fieldTypes.date]: [
          { value: "from", text: "FROM" },
          { value: "to", text: "TO" },
        ],
        [fieldTypes.number]: [
          { value: "greaterThan", text: "GREATER THAN" },
          { value: "lessThan", text: "LESS THAN" },
          { value: "equal", text: "EQUAL" },
        ],
        [fieldTypes.custom]: null,
      },
      ownershipValues: {
        meOnly: { value: 0, text: "Me only" },
        othersOnly: { value: 1, text: "Others only" },
        meAndOthers: { value: 2, text: "Me and others" },
      },
      filters: [],
      includeCharts: true,
      tableOptions: {
        sortBy: "date",
        perPage: 10,
        sortDesc: true,
        currentPage: 1,
      },
      selectedField: null,
      ownershipSetting: null,
    };
  },
  computed: {
    visibleFields() {
      var visibleFields = [];
      Object.values(this.fields).forEach((fieldData) => {
        if (this.visibility[fieldData.key]) {
          visibleFields.push(fieldData);
        }
      });
      return visibleFields;
    },
    fieldSelectOptions() {
      return Object.values(this.fields).map((fieldData) => {
        return { text: fieldData.label, value: fieldData.key };
      });
    },
    totalRows() {
      return this.payments.length;
    },
    totalIncome() {
      var total = 0;
      this.payments.forEach((payment) => {
        total += Number(payment.amount);
      });
      return total.toFixed(2);
    },
    pieChartData() {
      var data = {};
      this.payments.forEach((payment) => {
        if (data[payment.space_id]) {
          data[payment.space_id] += Number(payment.amount);
        } else {
          data[payment.space_id] = Number(payment.amount);
        }
      });
      for (let space of Object.keys(data)) {
        data[space] = Number(data[space].toFixed(2));
      }
      return data;
    },
    lineChartData() {
      return [
        {
          data: this.payments.map((payment) => ({
            x: new Date(payment.date).getTime(),
            y: Number(payment.amount),
          })),
        },
      ];
    },
  },
  methods: {
    getOptionsFromModes(modes) {
      return Object.entries(modes).map((entry) => {
        return { value: entry[0], text: entry[1] };
      });
    },
    createFilter() {
      this.filters.push({
        field: this.fields[this.selectedField],
        type: this.fields[this.selectedField].type,
        filter: {},
      });
    },
    deleteFilter(filterData) {
      this.filters.splice(this.filters.indexOf(filterData), 1);
    },
    formatPaymentData(data) {
      console.log(data[0]);
      // eslint-disable-next-line no-debugger
      // debugger;
      data.forEach((payment) => {
        payment.base_currency = payment.space.base_currency;
        payment.space_id = payment.space.id;
        payment.basket = String(payment.basket)
          .replace("[", "")
          .replace("]", "");
        payment.offers = payment.offers.map((offer) => offer.name);
      });
      return data;
    },
    fetchDataFromApi() {
      var filters = this.filters.map((filterData) => {
        return {
          mode: filterData.filter.mode,
          value: filterData.filter.value,
          field: filterData.field.key,
        };
      });
      var params = new URLSearchParams({
        expand: "space,offers,offer",
        filters: JSON.stringify(filters),
        owner: this.ownershipSetting,
      });
      this.axios
        .get(
          this.appConfig.BACKEND_API_URL + "payments/" + `?${params.toString()}`
        )
        .then((response) => {
          this.payments = this.formatPaymentData(response.data);
        });
    },
  },
  created() {
    this.ownershipSetting = this.ownershipValues.meAndOthers.value;
    let dateFrom = new Date(Date.now() - 30 * 1000 * 3600 * 24);
    dateFrom.setUTCHours(0, 0, 0, 0);
    let dateTo = new Date();
    dateTo.setUTCHours(23, 59, 59, 999);
    this.filters = [
      {
        field: this.fields.date,
        filter: {
          value: dateFrom.toISOString(),
          mode: this.filterModes[this.fieldTypes.date][0].value,
        },
        type: this.fieldTypes.date,
      },
      {
        field: this.fields.date,
        filter: {
          value: dateTo.toISOString(),
          mode: this.filterModes[this.fieldTypes.date][1].value,
        },
        type: this.fieldTypes.date,
      },
    ];
    this.fetchDataFromApi();
  },
};
</script>
<style lang="sass" scoped>
@import "src/assets/styles/base"

.dashboard-section
  background-color: $centi-light-blue
  padding: 15px
  margin-bottom: 30px

  .pagination
    width: max-content
    margin: auto

    button
      margin: 0!important

  h1
    margin: 0 0 15px!important

  button
    margin: 10px

table
  display: block!important
  overflow-x: scroll!important

  .card
    background-color: $centi-light-blue

th, td
  border-top: 0!important
  border-bottom: 5px solid $centi-light-blue!important
  background-color: $centi-light-gray!important

#filter-container
  background-color: whitesmoke
  display: flex
  flex-wrap: wrap

  #add-filter-button-container
    display: flex
    align-items: center

  .filter-tile
    margin: 5px

#visibility-settings
  display: flex
  flex-wrap: wrap
  margin-bottom: 10px

  *
    margin: 0 5px

#dashboard-charts
  display: flex
  justify-content: space-around
  align-items: center

#dashboard-filters
  margin-top: 30px
</style>
