<template>
  <div>
    <ThePromoRuleForm
      v-if="isFormReady"
      v-bind:allowed-options="allowedOptions"
      v-bind:token-choices="tokenChoices"
      v-bind:errors="errors"
      v-bind:instance="promoRule"
      form-name="global-promo-rule-details-form"
      v-on:submit="onSubmit($event)"
    />
    <b-modal
      id="modal-success"
      ok-only
      title="Success"
      v-on:hide="handleSuccessModalHidden"
    >
      <p class="my-4">Global promotion rule has been edited</p>
    </b-modal>
  </div>
</template>

<script>
import PromoRuleDetails from "./PromoRuleDetails";
import { GLOBAL_PROMO_RULE_DETAILS_ROUTE } from "../constants/relative_api_routes";
import { GLOBAL_PROMO_RULES } from "@/constants/page_names";
import { GLOBAL_PROMO_RULE_CONDITIONS_CHOICES } from "@/constants/promo_rules";

export default {
  name: "GlobalPromoRuleDetails",
  extends: PromoRuleDetails,
  data() {
    return {
      allowedOptions: GLOBAL_PROMO_RULE_CONDITIONS_CHOICES,
      promoRule: {},
      url:
        this.appConfig.BACKEND_API_URL +
        GLOBAL_PROMO_RULE_DETAILS_ROUTE(this.$route.params.id),
    };
  },
  methods: {
    handleSuccessModalHidden() {
      this.$router.push({ name: GLOBAL_PROMO_RULES });
    },
  },
};
</script>
