<template>
  <div id="promo-rules">
    <h1>Promotion Rules</h1>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "PromoRules",
};
</script>
