<template>
  <div id="offers-list">
    <b-row
      v-for="rowNumber in rows"
      v-bind:key="rowNumber"
      class="grid-row"
      v-bind:cols="columns"
    >
      <b-col
        v-for="colNumber in getColumnsForRow(rowNumber)"
        v-bind:key="colNumber"
        class="grid-column"
      >
        <TheCashierOfferListItem
          v-bind:offer="getOffer(rowNumber, colNumber)"
          v-on:click="selectOffer(getOffer(rowNumber, colNumber))"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TheCashierOfferListItem from "@/components/cashier/TheCashierOfferListItem";
import { CASHIER_OFFER_ROUTE } from "@/constants/relative_api_routes";
import storeAuthStatusGetters from "@/mixins/store_auth_status_getters";

export default {
  name: "TheCashierOfferList",
  components: { TheCashierOfferListItem },
  mixins: [storeAuthStatusGetters],
  props: {
    columns: {
      type: Number,
      required: false,
      default: 4,
    },
  },
  data() {
    return {
      offers: [],
    };
  },
  computed: {
    rows() {
      return Math.ceil(this.offers.length / this.columns);
    },
    offersUrl() {
      return (
        this.appConfig.BACKEND_API_URL +
        CASHIER_OFFER_ROUTE(
          this.$store.state.cashier.cashierId,
          this.$store.state.cashier.spaceId
        )
      );
    },
  },
  methods: {
    getOffer(rowNumber, colNumber) {
      return this.offers[--rowNumber * this.columns + --colNumber];
    },
    getColumnsForRow(rowNumber) {
      const isRowShorter = rowNumber * this.columns > this.offers.length;
      return isRowShorter
        ? this.offers.length - --rowNumber * this.columns
        : this.columns;
    },
    selectOffer(offer) {
      this.$emit("offerSelected", offer);
    },
    async getOffers() {
      this.axios.get(this.offersUrl).then((response) => {
        this.offers = response.data;
      });
    },
  },
  created() {
    this.getOffers();
  },
};
</script>

<style scoped lang="sass">
$custom-grid-padding: 0.5vw

#offers-list
  height: 100%
  overflow-y: auto
  display: flex
  flex-flow: column
  padding: calc(2 * #{$custom-grid-padding})
  background-color: whitesmoke

  .grid-row
    margin: 0 -#{$custom-grid-padding}
    padding-bottom: calc(2 * #{$custom-grid-padding})

  .grid-column
    padding-left: $custom-grid-padding
    padding-right: $custom-grid-padding
</style>
