<template>
  <div id="offers">
    <h1>Offers</h1>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Offers",
};
</script>
