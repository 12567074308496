<template>
  <div>
    <div id="global-promo-rules-table">
      <TableDisplay
        v-bind:items="promoRules"
        v-bind:fields="fields"
        v-bind:labels="labels"
        v-on:editCalled="handleEditCall($event)"
        v-on:deleteCalled="handleDeleteCall($event)"
      />
    </div>
    <div>
      <b-button variant="primary" v-on:click="promoRuleCreateRedirect">
        Create New Rule
      </b-button>
    </div>
    <b-modal
      id="modal-delete"
      title="Delete rule"
      cancel-variant="primary"
      v-on:ok="deletePromoRule"
    >
      <p class="my-4">Are you sure you want to delete the promotion rule?</p>
    </b-modal>
  </div>
</template>

<script>
import { GLOBAL_PROMO_RULE_LIST_CREATE } from "@/constants/relative_api_routes";
import {
  GLOBAL_PROMO_RULE_CREATE,
  GLOBAL_PROMO_RULE_DETAILS,
} from "@/constants/page_names";
import PromoRuleList from "@/views/PromoRuleList";

export default {
  name: "GlobalPromoRuleList",
  extends: PromoRuleList,
  computed: {
    url() {
      return `${this.appConfig.BACKEND_API_URL}${GLOBAL_PROMO_RULE_LIST_CREATE(
        this.userId
      )}?expand=return_token`;
    },
  },
  methods: {
    promoRuleCreateRedirect() {
      this.$router.push({ name: GLOBAL_PROMO_RULE_CREATE });
    },
    handleEditCall(promoRule) {
      this.$router.push({
        name: GLOBAL_PROMO_RULE_DETAILS,
        params: { id: promoRule.id },
      });
    },
  },
};
</script>
