<template>
  <div id="offer-details">
    <div class="form-container">
      <ApiForm
        v-on:success="$bvModal.show('modal-success')"
        v-bind="apiFormOptions"
      />
    </div>
    <b-modal
      id="modal-success"
      ok-only
      title="Success"
      v-on:hide="handleModalHidden"
    >
      <p class="my-4">Offer has been changed</p>
    </b-modal>
  </div>
</template>

<script>
import ApiForm from "@/components/base/ApiForm";
import { TOKENS_ROUTE, OFFER_ROUTE } from "@/constants/relative_api_routes";
import { OFFERS } from "@/constants/page_names";
import store_auth_status_getters from "@/mixins/store_auth_status_getters";

export default {
  name: "OfferDetails",
  components: { ApiForm },
  mixins: [store_auth_status_getters],
  data() {
    return {
      tokenChoices: [],
      offerDetails: {},
      targetUrl:
        this.appConfig.BACKEND_API_URL + OFFER_ROUTE(this.$route.params.id),
    };
  },
  computed: {
    apiFormOptions() {
      return {
        formName: "offer-details",
        targetUrl: this.targetUrl,
        action: "PUT",
        customFieldOptions: {
          offertoken_set: {
            type: "parameterized-select",
            label: "Tokens",
            lookup_field: "token",
            choices: this.tokenChoices,
            parameters: {
              quantity: {
                type: "number",
                required: true,
                label: "qty",
                min: 1,
              },
            },
            multiple: true,
            showLabels: false,
          },
          description: { type: "textarea" },
        },
        modelInstance: this.offerDetails,
      };
    },
  },
  methods: {
    handleModalHidden() {
      this.$router.push({ name: OFFERS });
    },
    async getTokens() {
      return await this.axios
        .get(this.appConfig.BACKEND_API_URL + TOKENS_ROUTE(this.userId), {
          params: { offerTokens: true },
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            this.tokenChoices.push({
              value: response.data[i].id,
              text: response.data[i].name,
            });
          }
        });
    },
    async getOfferDetails() {
      return await this.axios.get(this.targetUrl).then((response) => {
        this.offerDetails = response.data;
      });
    },
  },
  created() {
    this.getTokens();
    this.getOfferDetails();
  },
};
</script>
