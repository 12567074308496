<template>
  <div id="offer-creation-form">
    <div class="form-container">
      <GenericFormField label="Space">
        <b-form-select v-model="space" v-bind:options="spaceChoices" />
      </GenericFormField>
      <ApiForm
        v-if="space !== null"
        v-on:success="$bvModal.show('modal-success')"
        v-bind="apiFormOptions"
      />
    </div>
    <b-modal
      id="modal-success"
      ok-only
      title="Success"
      v-on:hide="handleModalHidden"
    >
      <p class="my-4">Offer has been created</p>
    </b-modal>
  </div>
</template>

<script>
import ApiForm from "@/components/base/ApiForm";
import {
  OFFER_CREATE_ROUTE,
  TOKENS_ROUTE,
  MERCHANT_SPACES_ROUTE,
} from "@/constants/relative_api_routes";
import { OFFERS } from "@/constants/page_names";
import store_auth_status_getters from "@/mixins/store_auth_status_getters";
import GenericFormField from "@/components/base/GenericFormField";

export default {
  name: "OfferCreate",
  components: { GenericFormField, ApiForm },
  mixins: [store_auth_status_getters],
  data() {
    return {
      tokenChoices: [],
      spaceChoices: [],
      space: null,
    };
  },
  computed: {
    apiFormOptions() {
      return {
        formName: "offer-create",
        targetUrl: `${this.appConfig.BACKEND_API_URL}${OFFER_CREATE_ROUTE(
          this.space
        )}`,
        customFieldOptions: {
          offertoken_set: {
            type: "parameterized-select",
            label: "Tokens",
            lookup_field: "token",
            choices: this.tokenChoices,
            parameters: {
              quantity: {
                type: "number",
                required: true,
                default: 1,
                min: 1,
              },
            },
            multiple: true,
            showLabels: false,
          },
          description: { type: "textarea" },
          cashier_visibility: { default: true },
          mobile_visibility: { default: true },
        },
      };
    },
  },
  methods: {
    handleModalHidden() {
      this.$router.push({ name: OFFERS });
    },
    async getTokens() {
      return await this.axios
        .get(this.appConfig.BACKEND_API_URL + TOKENS_ROUTE(this.userId), {
          params: { offerTokens: true },
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            this.tokenChoices.push({
              value: response.data[i].id,
              text: response.data[i].name,
            });
          }
        });
    },
    async getSpaces() {
      return await this.axios
        .get(
          this.appConfig.BACKEND_API_URL + MERCHANT_SPACES_ROUTE(this.userId)
        )
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            this.spaceChoices.push({
              value: response.data[i].id,
              text: response.data[i].name,
            });
          }
        });
    },
  },
  created() {
    this.getTokens();
    this.getSpaces();
  },
};
</script>
