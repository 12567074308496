<template>
  <div id="qr-scanner">
    <b-navbar fixed="top" variant="primary" type="light">
      <b-navbar-brand href="#">
        <img
          src="@/assets/images/centi_white_logo.svg"
          alt="CENTI"
          id="centi-logo"
        />
        <img src="@/assets/images/icons/scanner.svg" alt="QR" />
        <span id="scanner-page-title">Scanner</span>
      </b-navbar-brand>
    </b-navbar>
    <div id="qr-container">
      <b-alert
        class="alert"
        variant="danger"
        v-bind:show="failAlertCountDown"
        dismissable
        fade
        v-on:dismissed="failAlertCountDown = 0"
      >
        {{ errorMessage }}
      </b-alert>
      <b-alert
        class="alert"
        variant="success"
        v-bind:show="successAlertCountDown"
        dismissable
        fade
        v-on:dismissed="successAlertCountDown = 0"
      >
        Activation successful
      </b-alert>
      <div id="scanner-container" v-if="!content">
        <TheQRScanner
          v-on:decoded="captureContent($event)"
          v-if="isScanning"
          v-on:stop="isScanning = false"
        />
        <div v-if="!isScanning">
          <b-button
            class="scan-button"
            variant="primary"
            size="lg"
            v-on:click="startScanning"
          >
            Start scanning
          </b-button>
        </div>
      </div>
      <TheTokenDetailsVue
        v-if="!isScanning && content"
        v-bind:collection-url="content"
        v-bind:supplier-id="$store.state.scanner.supplierId"
        v-on:decline="showFailAlert($event)"
        v-on:accept="showSuccessAlert"
      />
    </div>
  </div>
</template>

<script>
import TheQRScanner from "@/components/qr_scanner/TheQRScanner";
import TheTokenDetailsVue from "@/components/qr_scanner/TheTokenDetails";
import { SPACE_ROUTE } from "@/constants/relative_api_routes";
import standalonePageAuth from "@/mixins/standalone_page_auth";

export default {
  name: "Scanner",
  components: { TheQRScanner, TheTokenDetailsVue },
  mixins: [standalonePageAuth],
  data() {
    return {
      content: null,
      isScanning: false,
      successAlertCountDown: 0,
      failAlertCountDown: 0,
      alertTimer: 5,
      errorMessage: "",
      spaceData: {},
      storeNamespace: "scanner",
    };
  },
  computed: {
    spaceBackendUrl() {
      return (
        this.appConfig.BACKEND_API_URL +
        SPACE_ROUTE(this.$store.state.scanner.spaceId)
      );
    },
  },
  methods: {
    startScanning() {
      this.isScanning = true;
      this.successAlertCountDown = 0;
      this.failAlertCountDown = 0;
    },
    captureContent(content) {
      this.isScanning = false;
      this.content = content;
    },
    showSuccessAlert() {
      this.content = null;
      this.successAlertCountDown = this.alertTimer;
    },
    showFailAlert(message) {
      this.content = null;
      this.errorMessage = message;
      this.failAlertCountDown = this.alertTimer;
    },
    getSpace() {
      this.axios.get(this.spaceBackendUrl).then((response) => {
        this.spaceData = response.data;
      });
    },
    getAuthCredentials() {
      const spaceId = this.$route.query.space;
      const collectorId = this.$route.query.id;
      if (!collectorId || !spaceId) {
        return null;
      }
      return { spaceId, collectorId };
    },
    onAuthSuccess() {
      this.getSpace();
    },
  },
};
</script>

<style scoped lang="sass">
nav
  #scanner-page-title
    color: white
    font-weight: bold

  img
    margin-right: 10px

#qr-container
  margin-top: 30px
  height: 70vh
  max-width: 100vw
  background-color: whitesmoke
  position: relative
  display: flex
  flex-direction: column
  justify-content: space-evenly
  align-items: center

  #scanner-container
    height: inherit
    width: inherit
    display: flex
    flex-direction: column
    justify-content: space-evenly
    align-items: center

  .alert
    position: absolute
    top: 0
    width: 100%
</style>
