<template>
  <TheAccessUrlManager
    v-bind:list-url="collectorsBackendUrl"
    v-bind:update-url="getUpdateUrl"
    v-bind:url-generator="getScannerPageUrl"
    item-name="collector"
  />
</template>

<script>
import {
  COLLECTOR_LIST_CREATE,
  COLLECTOR_ROUTE,
} from "@/constants/relative_api_routes";
import { SCANNER } from "@/constants/page_names";
import TheAccessUrlManager from "@/components/base/TheAccessUrlManager";

export default {
  name: "TheCollectorList",
  components: { TheAccessUrlManager },
  props: {
    spaceId: {
      type: String,
      required: true,
    },
  },
  computed: {
    collectorsBackendUrl() {
      return (
        this.appConfig.BACKEND_API_URL + COLLECTOR_LIST_CREATE(this.spaceId)
      );
    },
  },
  methods: {
    getUpdateUrl(collectorId) {
      return this.appConfig.BACKEND_API_URL + COLLECTOR_ROUTE(collectorId);
    },
    getScannerPageUrl(collectorId) {
      const scannerPagePath = this.$router.resolve({ name: SCANNER }).href;
      const query = new URLSearchParams({
        space: this.spaceId,
        id: collectorId,
      }).toString();
      return window.location.origin.concat("/", scannerPagePath, "?", query);
    },
  },
};
</script>
