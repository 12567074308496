<template>
  <b-dropdown
    id="user-button"
    variant="primary outline-light"
    right
    v-if="isAuthenticated"
  >
    <template #button-content>
      <strong>User</strong>
    </template>
    <b-dropdown-item v-on:click="goToProfile"> Profile </b-dropdown-item>
    <b-dropdown-item id="auth-button" v-on:click="handleAuthentication">
      Sign Out
    </b-dropdown-item>
  </b-dropdown>
  <div v-else>
    <b-button variant="light" id="signup-button" v-on:click="redirectToSignUp">
      Sign Up
    </b-button>
    <b-button
      variant="outline-light"
      id="auth-button"
      v-on:click="handleAuthentication"
    >
      Sign In
    </b-button>
  </div>
</template>

<script>
import { LOGOUT_ACTION } from "@/constants/store_actions";
import store_auth_status_getters from "@/mixins/store_auth_status_getters";
import { HOME_PAGE, SIGN_IN, MERCHANT, SIGN_UP } from "@/constants/page_names";

export default {
  name: "TheNavbarAuthButton",
  mixins: [store_auth_status_getters],
  methods: {
    handleAuthentication() {
      if (this.isAuthenticated) {
        this.$store.dispatch(LOGOUT_ACTION).then(() => {
          if (
            this.$route.path !==
            this.$router.resolve({ name: HOME_PAGE }).route.path
          ) {
            this.$router.push({ name: HOME_PAGE });
          }
        });
      } else if (
        this.$route.path !== this.$router.resolve({ name: SIGN_IN }).route.path
      ) {
        this.$router.push({ name: SIGN_IN });
      }
    },
    redirectToSignUp() {
      this.$router.push({ name: SIGN_UP });
    },
    goToProfile() {
      if (
        this.$route.path !== this.$router.resolve({ name: MERCHANT }).route.path
      ) {
        this.$router.push({ name: MERCHANT });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "src/assets/styles/base"

#auth-button
  width: 150px
  margin-left: 5px

  &:hover
    background-color: white
    color: $centi-blue
    transition: 0.5s

#signup-button
  width: 150px
  color: $centi-blue

  &:hover
    background-color: $centi-blue
    border: 1px solid white
    color: white
    transition: 0.5s

#user-button
  width: 80px
  margin-left: 10px
</style>
