<template>
  <div id="timer-container">
    <template v-if="isSessionValid">
      <span id="counter">{{ minutes }}:{{ seconds }}</span>
      <span>
        <b-icon
          id="refresh-button"
          class="icon-button"
          icon="arrow-clockwise"
          v-on:click="performRefresh"
        />
      </span>
    </template>
    <template v-else>
      <span id="timeout-text">{{ sessionExpiredText }}</span>
    </template>
    <b-modal
      id="modal-timeout-warning"
      no-close-on-esc
      no-close-on-backdrop
      hide-header
      hide-footer
    >
      <div>
        <p class="text-center" id="timout-warning-message">
          {{ timeoutWarningText }}
        </p>
      </div>
      <b-button block variant="primary" v-on:click="performRefresh">
        <template v-if="isSessionValid">Prolong my session</template>
        <template v-else>OK</template>
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  TOKEN_REFRESH_ACTION,
  SESSION_COUNTDOWN_ACTION,
} from "@/constants/store_actions";
import { SIGN_IN } from "@/constants/page_names";
import store_auth_status_getters from "@/mixins/store_auth_status_getters";

export default {
  name: "TheSessionTimer",
  mixins: [store_auth_status_getters],
  data() {
    return {
      timerText: "Session ends in",
      sessionExpiredText: "Session expired!",
      criticalRemainingTime: 20,
    };
  },
  computed: {
    minutes() {
      return Math.floor(this.remainingSessionTime / 60);
    },
    seconds() {
      let seconds = (this.remainingSessionTime % 60).toString();
      return seconds.length === 1 ? "0" + seconds : seconds;
    },
    timeoutWarningText() {
      if (this.isSessionValid) {
        let secondsLeft = this.remainingSessionTime % 60;
        return `Your session will expire in ${secondsLeft} seconds!\nPress the button below to stay logged-in!`;
      }
      return "Your session has expired!";
    },
    isSessionValid() {
      return this.remainingSessionTime > 0;
    },
  },
  watch: {
    remainingSessionTime: {
      handler(value) {
        if (value === this.criticalRemainingTime) {
          this.$bvModal.show("modal-timeout-warning");
        }
      },
      immediate: true,
    },
    authToken: {
      handler() {
        this.startCountdown();
      },
      immediate: true,
    },
  },
  methods: {
    performRefresh() {
      this.$store
        .dispatch(TOKEN_REFRESH_ACTION)
        .then(() => this.$bvModal.hide("modal-timeout-warning"))
        .catch(() => this.$router.push({ name: SIGN_IN }));
    },
    startCountdown() {
      this.$store.dispatch(
        SESSION_COUNTDOWN_ACTION,
        this.appConfig.SESSION_DURATION_SECONDS
      );
    },
  },
  created() {
    this.startCountdown();
  },
};
</script>

<style lang="sass" scoped>

#timer-container
  display: flex
  color: white
  align-self: center
  justify-content: space-between

#counter
  text-align: right
  width: 50px
  margin-right: 10px

#timeout-text
  margin-left: auto

#timout-warning-message
  white-space: pre
</style>
