import Vue from "vue";
import * as mutationTypes from "@/constants/mutation_types";
import * as storeActions from "@/constants/store_actions";
import { COLLECTOR_LOGIN_ROUTE } from "@/constants/relative_api_routes";

async function authenticate(collectorId) {
  return await Vue.axios
    .post(Vue.prototype.appConfig.BACKEND_API_URL + COLLECTOR_LOGIN_ROUTE, {
      uid: collectorId,
    })
    .then((response) => response.data)
    .catch((error) => console.error(error));
}

export default {
  namespaced: true,
  state: () => ({
    status: "",
    token: "",
    collectorId: "",
    spaceId: "",
    supplierId: "",
  }),
  mutations: {
    [mutationTypes.AUTH_REQUEST](state) {
      state.status = "loading";
    },
    [mutationTypes.AUTH_SUCCESS](state, payload) {
      state.status = "success";
      state.token = payload.token;
      state.collectorId = payload.collectorId;
      state.spaceId = payload.spaceId;
      state.supplierId = payload.supplierId;
    },
    [mutationTypes.AUTH_LOGOUT](state) {
      state.status = "";
      state.token = "";
      state.collectorId = "";
      state.spaceId = "";
      state.supplierId = "";
    },
    [mutationTypes.TOKEN_REFRESH_REQUEST](state) {
      state.status = "refresh";
    },
    [mutationTypes.TOKEN_REFRESH_SUCCESS](state, token) {
      state.status = "success";
      state.token = token;
    },
    [mutationTypes.TOKEN_REFRESH_ERROR](state) {
      state.status = "error";
    },
  },
  actions: {
    [storeActions.LOGIN_ACTION]({ commit }, loginData) {
      return new Promise((resolve, reject) => {
        const collectorId = loginData.collectorId;
        const spaceId = loginData.spaceId;
        commit(mutationTypes.AUTH_REQUEST);
        authenticate(collectorId)
          .then((collectorData) => {
            const token = collectorData["token"];
            const supplierId = collectorData["supplier"];
            Vue.prototype.axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${token}`;
            commit(mutationTypes.AUTH_SUCCESS, {
              token,
              collectorId,
              spaceId,
              supplierId,
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [storeActions.LOGOUT_ACTION]({ commit }) {
      return new Promise((resolve) => {
        commit(mutationTypes.AUTH_LOGOUT);
        delete Vue.prototype.axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    [storeActions.TOKEN_REFRESH_ACTION]({ state, commit }) {
      return new Promise((resolve, reject) => {
        commit(mutationTypes.TOKEN_REFRESH_REQUEST);
        authenticate(state.collectorId)
          .then((collectorData) => {
            const token = collectorData["token"];
            Vue.prototype.axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${token}`;
            commit(mutationTypes.TOKEN_REFRESH_SUCCESS, token);
            resolve(token);
          })
          .catch((error) => {
            commit(mutationTypes.TOKEN_REFRESH_ERROR);
            reject(error);
          });
      });
    },
  },
};
