<template>
  <div>
    <b-form-file accept=".csv" v-model="CSVFile" />
    <TheTopUpForm
      v-if="walletAddresses.length > 0"
      v-bind:wallet-addresses="walletAddresses"
      v-on:submit="handleSubmit($event)"
    />
  </div>
</template>

<script>
import TheTopUpForm from "@/components/wallets/TheTopUpForm";
export default {
  name: "TheTopUpCSVForm",
  components: { TheTopUpForm },
  data() {
    return {
      CSVFile: null,
      walletAddresses: [],
      addressLookupHeader: "address",
      CSVSeparator: ",",
    };
  },
  watch: {
    async CSVFile(newValue) {
      if (!newValue) {
        this.walletAddresses = [];
      } else {
        const content = await newValue.text();
        const csvFileData = this.getCSVFileData(content);
        let addresses = [];
        for (
          let lineIndex = csvFileData.startIndex;
          lineIndex < csvFileData.lines.length;
          lineIndex++
        ) {
          const lineContents = csvFileData.lines[lineIndex].split(
            this.CSVSeparator
          );
          const address = lineContents[csvFileData.addressIndex];
          if (address) addresses.push(address);
        }
        this.walletAddresses = addresses;
      }
    },
  },
  methods: {
    getCSVFileData(fileContent) {
      const lines = fileContent.split(/\r?\n/);
      const firstLineContents = lines[0].split(this.CSVSeparator);
      const addressHeader = firstLineContents.find((header) =>
        header.includes(this.addressLookupHeader)
      );
      const addressIndex = addressHeader
        ? firstLineContents.indexOf(addressHeader)
        : 0;
      const startIndex = addressHeader ? 1 : 0;
      return { lines, addressIndex, startIndex };
    },
    // For Top-up functionality mock only
    handleSubmit(topUpData) {
      const requestData = this.walletAddresses.map((address) => {
        return {
          address,
          currency: topUpData.currency,
          amount: topUpData.amount,
        };
      });
      this.$emit("submit", requestData);
    },
  },
};
</script>
