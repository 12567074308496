<template>
  <div id="merchant-details">
    <div class="form-container">
      <ApiForm
        v-on:success="$bvModal.show('modal-success')"
        v-bind="apiFormOptions"
      />
    </div>
    <b-modal
      id="modal-success"
      ok-only
      title="Success"
      v-on:hide="handleModalHidden"
    >
      <p class="my-4">Profile has been changed</p>
    </b-modal>
  </div>
</template>

<script>
import ApiForm from "@/components/base/ApiForm";
import { MERCHANT_ROUTE } from "@/constants/relative_api_routes";
import { HOME_PAGE } from "@/constants/page_names";
import {
  PHONE_FIELD,
  TEXTAREA_FIELD,
} from "@/constants/form_field_types_identifiers";
import storeAuthStatusGetters from "@/mixins/store_auth_status_getters";

export default {
  name: "Merchant",
  components: { ApiForm },
  mixins: [storeAuthStatusGetters],
  data() {
    return {
      merchantDetails: {},
    };
  },
  methods: {
    handleModalHidden() {
      this.$router.push({ name: HOME_PAGE });
    },
    async getMerchantDetails() {
      return await this.axios.get(this.url);
    },
  },
  computed: {
    apiFormOptions() {
      return {
        formName: "merchant-details",
        targetUrl: `${this.appConfig.BACKEND_API_URL}${MERCHANT_ROUTE(
          this.userId
        )}`,
        action: "PUT",
        hiddenFields: ["spaces_supplier"],
        modelInstance: this.merchantDetails,
        customFieldOptions: {
          phone_number: { type: PHONE_FIELD },
          description: { type: TEXTAREA_FIELD },
        },
      };
    },
    url() {
      return this.appConfig.BACKEND_API_URL + MERCHANT_ROUTE(this.userId);
    },
  },
  created() {
    this.getMerchantDetails().then((response) => {
      this.merchantDetails = response.data;
    });
  },
};
</script>

<style lang="sass">
#merchant-details-email-input
  pointer-events: none
  background-color: #b6bdc5
</style>
