<template>
  <div id="collectors">
    <h1>Collectors</h1>
    <TheSpaceSelector v-slot="slotProps">
      <TheCollectorList
        v-bind:space-id="slotProps.spaceId"
        v-bind:key="slotProps.spaceId"
      />
    </TheSpaceSelector>
  </div>
</template>

<script>
import storeAuthStatusGetters from "@/mixins/store_auth_status_getters";
import TheCollectorList from "@/components/collectors/TheCollectorList";
import TheSpaceSelector from "@/components/base/TheSpaceSelector";

export default {
  name: "Collectors",
  mixins: [storeAuthStatusGetters],
  components: { TheCollectorList, TheSpaceSelector },
};
</script>
