<template>
  <div>
    <div id="offers-table">
      <TableDisplay
        v-bind:items="offers"
        v-bind:fields="fields"
        v-bind:labels="labels"
        icon="icon"
        v-on:editCalled="handleEditCall($event)"
        v-on:deleteCalled="handleDeleteCall($event)"
      />
    </div>
    <div>
      <b-button variant="primary" v-on:click="offerCreateRedirect">
        Create New Offer
      </b-button>
    </div>
    <b-modal
      id="modal-delete"
      title="Delete offer"
      cancel-variant="outline-primary"
      v-on:ok="deleteOffer"
    >
      <p class="my-4">Are you sure you want to delete the offer?</p>
    </b-modal>
  </div>
</template>

<script>
import storeAuthStatusGetters from "@/mixins/store_auth_status_getters";
import dataReformatter from "@/mixins/data_reformatter";
import {
  MERCHANT_OFFERS_ROUTE,
  OFFER_ROUTE,
} from "@/constants/relative_api_routes";
import { OFFER_CREATE, OFFER_DETAILS } from "@/constants/page_names";
import TableDisplay from "@/components/base/TableDisplay";

export default {
  name: "OfferList",
  components: { TableDisplay },
  mixins: [storeAuthStatusGetters, dataReformatter],
  data() {
    return {
      offers: [],
      selectedOffer: null,
      fields: {
        basics: ["id", "icon", "name", "type", "space", "registered_at"],
        details: [
          "name",
          "icon",
          "base_price",
          "type",
          "description",
          "space",
          "tokens",
          "registered_at",
          "updated_at",
        ],
      },
      labels: { space: "Space" },
    };
  },
  computed: {
    url() {
      return `${this.appConfig.BACKEND_API_URL}${MERCHANT_OFFERS_ROUTE(
        this.userId
      )}?expand=tokens,space`;
    },
  },
  methods: {
    async getOffers() {
      return await this.axios.get(this.url).then((response) => {
        this.offers = this.parseData(response.data);
      });
    },
    handleDeleteCall(offer) {
      this.selectedOffer = offer;
      this.$bvModal.show("modal-delete");
    },
    handleEditCall(offer) {
      this.$router.push({ name: OFFER_DETAILS, params: { id: offer.id } });
    },
    offerCreateRedirect() {
      this.$router.push({ name: OFFER_CREATE });
    },
    deleteOffer() {
      this.axios
        .delete(
          this.appConfig.BACKEND_API_URL + OFFER_ROUTE(this.selectedOffer.id)
        )
        .then(() => {
          this.getOffers();
        });
    },
    parseTokenData(offer) {
      let tokenList = offer.tokens.map((token) => {
        let offerToken = offer.offertoken_set
          .filter((offerToken) => offerToken.token === token.id)
          .pop();
        return `${offerToken.quantity}x ${token.name}`;
      });
      return tokenList.join(", ");
    },
    parseData(offers) {
      offers.map((merchantOffer) => {
        merchantOffer.space = merchantOffer.space.name;
        merchantOffer.registered_at = this.parseDatetime(
          merchantOffer.registered_at
        );
        merchantOffer.updated_at = this.parseDatetime(merchantOffer.updated_at);
        merchantOffer.tokens = this.parseTokenData(merchantOffer);
      });
      return offers;
    },
  },
  created() {
    this.getOffers();
  },
};
</script>
