<template>
  <div>
    <ThePromoRuleForm
      v-bind:allowed-options="allowedOptions"
      v-bind:token-choices="tokenChoices"
      v-bind:errors="errors"
      form-name="global-promo-rule-creation-form"
      v-on:submit="onSubmit($event)"
    />
    <b-modal
      id="modal-success"
      ok-only
      title="Success"
      v-on:hide="handleSuccessModalHidden"
    >
      <p class="my-4">Global Promotion rule has been created</p>
    </b-modal>
  </div>
</template>

<script>
import PromoRuleCreate from "@/views/PromoRuleCreate";
import { GLOBAL_PROMO_RULE_LIST_CREATE } from "@/constants/relative_api_routes";
import { GLOBAL_PROMO_RULES } from "@/constants/page_names";
import { GLOBAL_PROMO_RULE_CONDITIONS_CHOICES } from "@/constants/promo_rules";

export default {
  name: "GlobalPromoRuleCreate",
  extends: PromoRuleCreate,
  data() {
    return {
      allowedOptions: GLOBAL_PROMO_RULE_CONDITIONS_CHOICES,
    };
  },
  computed: {
    url() {
      return `${this.appConfig.BACKEND_API_URL}${GLOBAL_PROMO_RULE_LIST_CREATE(
        this.userId
      )}`;
    },
  },
  methods: {
    handleSuccessModalHidden() {
      this.$router.push({ name: GLOBAL_PROMO_RULES });
    },
  },
};
</script>
