<template>
  <component
    v-if="isFormReady"
    v-bind:is="formComponent"
    v-bind:form-name="formName"
    v-bind:field-data="fieldData"
    v-bind:model-data="modelData"
    v-bind:errors="errors"
    v-bind:conditional-fields="conditionalFields"
    v-on:submit="onSubmit"
  />
</template>

<script>
import BaseForm from "@/components/base/BaseForm";
import * as fieldTypes from "@/constants/form_field_types_identifiers";

export default {
  name: "ApiForm",
  components: { BaseForm },
  emits: ["submit", "success", "fail", "processing"],
  props: {
    formName: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: false,
      default: () => {
        return "POST";
      },
    },
    targetUrl: {
      type: String,
      required: false,
    },
    optionsUrl: {
      type: String,
      default: "",
      required: false,
    },
    customFieldOptions: {
      type: Object,
      default: () => {
        return {};
      },
      required: false,
    },
    hiddenFields: {
      type: Array,
      default: () => {
        return [];
      },
      required: false,
    },
    conditionalFields: {
      type: Map,
      default: () => {
        return new Map();
      },
      required: false,
    },
    modelInstance: {
      type: Object,
      default: () => {
        return {};
      },
      required: false,
    },
    clean: {
      type: Function,
      default: (formData) => {
        return formData;
      },
      required: false,
    },
    formComponent: {
      type: Object,
      default: () => BaseForm,
      required: false,
    },
  },
  data() {
    return {
      modelData: {},
      sourceFieldData: {},
      errors: {},
      isFormReady: false,
    };
  },
  computed: {
    derivedDataSourceUrl() {
      return this.optionsUrl === "" ? this.targetUrl : this.optionsUrl;
    },
    dateTimeFields() {
      return Object.keys(this.fieldData).filter(
        (fieldName) => this.fieldData[fieldName].type === fieldTypes.DATE_FIELD
      );
    },
    phoneFields() {
      return Object.keys(this.fieldData).filter(
        (fieldName) => this.fieldData[fieldName].type === fieldTypes.PHONE_FIELD
      );
    },
    fieldData() {
      let fieldData = {};
      let allFields = Object.keys(
        Object.assign({}, this.sourceFieldData, this.customFieldOptions)
      );
      for (let fieldName of allFields) {
        if (!this.hiddenFields.includes(fieldName)) {
          if (fieldName in this.sourceFieldData) {
            fieldData[fieldName] = Object.assign(
              {},
              this.sourceFieldData[fieldName],
              this.customFieldOptions[fieldName]
            );
          } else {
            fieldData[fieldName] = Object.assign(
              {},
              this.customFieldOptions[fieldName]
            );
          }
        }
      }
      return fieldData;
    },
    defaultModelValues() {
      let modelData = {};
      let fieldOptions = Object.assign(
        {},
        this.sourceFieldData,
        this.customFieldOptions
      );
      for (let fieldName in fieldOptions) {
        if (this.modelInstance[fieldName] !== undefined) {
          modelData[fieldName] = this.modelInstance[fieldName];
        } else if (fieldOptions[fieldName].default !== undefined) {
          modelData[fieldName] = fieldOptions[fieldName].default;
        }
      }
      return modelData;
    },
  },
  watch: {
    defaultModelValues(value) {
      this.modelData = Object.assign({}, this.modelData, value);
    },
  },
  methods: {
    async fetchFormFieldsFromApi() {
      let response = await this.axios.options(this.derivedDataSourceUrl);
      return response.data.actions[this.action.toUpperCase()];
    },
    parseFieldOptions(fieldOptions) {
      for (let fieldName in fieldOptions) {
        if (fieldOptions[fieldName].read_only) {
          delete fieldOptions[fieldName];
        } else {
          if (fieldOptions[fieldName].type === "string") {
            fieldOptions[fieldName].type = fieldTypes.TEXT_FIELD;
          } else if (fieldOptions[fieldName].type === "datetime") {
            fieldOptions[fieldName].type = fieldTypes.DATE_FIELD;
          } else if (
            ["float", "decimal"].includes(fieldOptions[fieldName].type)
          ) {
            fieldOptions[fieldName].type = fieldTypes.NUMBER_FIELD;
            fieldOptions[fieldName].default = 0;
            fieldOptions[fieldName].step = "0.01";
          } else if (fieldOptions[fieldName].type === "field") {
            fieldOptions[fieldName].type = fieldTypes.CHOICE_FIELD;
            fieldOptions[fieldName].multiple = false;
          } else if (fieldOptions[fieldName].type === "boolean") {
            fieldOptions[fieldName].type = fieldTypes.CHECKBOX_FIELD;
            fieldOptions[fieldName].default = false;
          } else if (fieldOptions[fieldName].type === "choice") {
            fieldOptions[fieldName].multiple = false;
            fieldOptions[fieldName].choices.forEach((choice) => {
              choice["text"] = choice.display_name;
              delete choice.display_name;
            });
          }
        }
      }
      return fieldOptions;
    },
    fetchData() {
      return this.fetchFormFieldsFromApi().then((fieldOptions) => {
        this.sourceFieldData = this.parseFieldOptions(fieldOptions);
        this.isFormReady = true;
      });
    },
    cleanData(data) {
      data = this.clean(data);
      this.dateTimeFields.forEach((dateField) => {
        if (data[dateField] !== undefined) {
          data[dateField] += " 00:00";
        }
      });
      this.phoneFields.forEach((phoneField) => {
        if (data[phoneField] !== undefined) {
          data[phoneField] = data[phoneField].replace(/\s/g, "");
        }
      });
      return data;
    },
    onSubmit() {
      if (this.$listeners.submit) {
        this.$emit("submit", this.cleanData(Object.assign({}, this.modelData)));
      } else {
        this.submit();
      }
    },
    submit(payload = null) {
      this.$emit("processing");
      payload = payload || this.cleanData(Object.assign({}, this.modelData));
      this.axios[this.action.toLowerCase()](this.targetUrl, payload)
        .then((response) => {
          this.$emit("success", response.data);
        })
        .catch((error) => {
          this.$emit("fail", error.response.data);
          if (error.response.status >= 400 && error.response.status < 500) {
            this.errors = error.response.data;
          } else {
            this.errors = { _internal: error.response.statusText };
          }
        });
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
