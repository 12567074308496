export const PAYMENT_STATES = Object.fromEntries(
  [
    "INITIATED",
    "APPROVED",
    "INVOICED",
    "REJECTED",
    "CONFIRMED",
    "SETTLED",
    "CANCELED",
  ].map((stateName) => [stateName, stateName])
);

export const PAYMENT_FINALIZED_STATES = Object.values(PAYMENT_STATES).splice(4);
