<template>
  <b-form
    v-bind:id="`${idNameDisplay}form`"
    v-on:submit.stop.prevent="onSubmit"
  >
    <ErrorDisplay
      v-bind:errors="nonFieldErrors"
      v-bind:id="`${idNameDisplay}errors`"
    />
    <div
      v-for="(fieldProperties, fieldName) in fieldData"
      v-bind:key="fieldName"
    >
      <GenericFormField
        v-if="isVisible(fieldName)"
        v-model="modelData[fieldName]"
        v-bind:field-name="fieldName"
        v-bind:field-properties="fieldProperties"
        v-bind:error="errors[fieldName]"
      />
    </div>
    <b-button v-bind:disabled="!isFormFilled" type="submit" variant="primary"
      ><slot>Submit</slot></b-button
    >
  </b-form>
</template>

<script>
import basicForm from "@/mixins/basic_form";

export default {
  name: "BaseForm",
  mixins: [basicForm],
  computed: {
    idNameDisplay() {
      return !this.formName ? "" : `${this.formName}-`;
    },
  },
};
</script>
