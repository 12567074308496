import { mapState } from "vuex";

export default {
  computed: mapState({
    isAuthenticated: (state) => !!state.token,
    authToken: (state) => state.token,
    status: (state) => state.status,
    user: (state) => state.username,
    userId: (state) => state.userId,
    banner: (state) => state.banner,
    remainingSessionTime: (state) => state.remainingSessionTime,
  }),
};
