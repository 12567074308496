<template>
  <b-form-group
    v-bind:id="fieldName ? `input-group-${inputId}` : null"
    v-bind:label="fieldLabel"
    v-bind:label-for="`${inputId}-input`"
    label-align="right"
    label-cols="4"
    label-cols-lg="3"
    v-bind:invalid-feedback="
      fieldProperties['type'] === fieldTypes.PARAMETERIZED_SELECT ? null : error
    "
    v-bind:state="state"
  >
    <template v-slot:label v-if="fieldProperties.required">
      <strong>
        {{ fieldLabel }}
      </strong>
    </template>

    <div class="row">
      <div class="col">
        <slot v-bind:fieldProperties="fieldProperties">
          <ParameterizedSelect
            v-if="fieldProperties['type'] === fieldTypes.PARAMETERIZED_SELECT"
            v-bind:id="`${inputId}-input`"
            v-bind:value="value"
            v-bind:lookup-field="fieldProperties['lookup_field']"
            v-bind:options="fieldProperties['choices']"
            v-bind:parameters="fieldProperties['parameters']"
            v-bind:multiple="fieldProperties['multiple']"
            v-bind:show-labels="fieldProperties['showLabels']"
            v-bind:required="fieldProperties['required']"
            v-bind:errors="error"
            v-on:input="onInput($event)"
          />
          <vue-tel-input
            v-else-if="fieldProperties['type'] === fieldTypes.PHONE_FIELD"
            v-bind:id="`${inputId}-input`"
            v-bind:value="value"
            mode="international"
            v-bind:input-options="{
              required: fieldProperties['required'],
              maxlength: 15,
            }"
            v-bind:dropdownOptions="{
              showFlags: true,
              showDialCodeInSelection: false,
            }"
            styleClasses="form-control custom-tel-input"
            v-on:input="onInput($event)"
          >
            <template v-slot:arrow-icon>
              <span>▼</span>
            </template>
          </vue-tel-input>
          <b-form-select
            v-else-if="fieldProperties['type'] === fieldTypes.CHOICE_FIELD"
            v-bind:id="`${inputId}-input`"
            v-bind:value="value"
            v-bind:options="fieldProperties['choices']"
            v-bind:required="fieldProperties['required']"
            v-bind:state="state"
            v-bind:multiple="fieldProperties['multiple']"
            v-on:input="onInput($event)"
            v-on:change="onChange($event)"
          />
          <b-form-datepicker
            v-else-if="fieldProperties['type'] === fieldTypes.DATE_FIELD"
            v-bind:id="`${inputId}-input`"
            v-bind:value="value"
            v-bind:required="fieldProperties['required']"
            v-bind:state="state"
            v-on:input="onInput($event)"
          />
          <b-form-textarea
            v-else-if="fieldProperties['type'] === fieldTypes.TEXTAREA_FIELD"
            v-bind:id="`${inputId}-input`"
            v-bind:value="value"
            v-bind:required="fieldProperties['required']"
            v-bind:rows="fieldProperties['rows'] ? fieldProperties['rows'] : 6"
            v-bind:max-rows="
              fieldProperties['max-rows'] ? fieldProperties['max-rows'] : 6
            "
            v-bind:state="state"
            v-on:input="onInput($event)"
            v-on:change="onChange($event)"
            v-on:update="onUpdate($event)"
          />
          <b-form-checkbox
            v-else-if="fieldProperties['type'] === fieldTypes.CHECKBOX_FIELD"
            v-bind:id="`${inputId}-input`"
            v-bind:checked="value"
            v-bind:required="fieldProperties['required']"
            v-bind:state="state"
            v-on:input="onInput($event)"
            v-on:change="onChange($event)"
          />
          <b-form-input
            v-else
            v-bind:id="`${inputId}-input`"
            v-bind:value="value"
            v-bind:required="fieldProperties['required']"
            v-bind:type="fieldProperties['type']"
            v-bind:step="fieldProperties['step']"
            v-bind:min="fieldProperties['min']"
            v-bind:max="fieldProperties['max']"
            v-bind:autocomplete="fieldProperties['autocomplete']"
            v-bind:state="state"
            v-on:input="onInput($event)"
            v-on:change="onChange($event)"
            v-on:update="onUpdate($event)"
          />
        </slot>
      </div>
      <div class="col-1 center-alignment form-input-appendix">
        <template v-if="fieldProperties['help_text']">
          <b-icon v-bind:id="`${inputId}-info`" icon="info-circle" />
          <b-tooltip
            v-bind:target="`${inputId}-info`"
            triggers="hover"
            variant="info"
          >
            {{ fieldProperties["help_text"] }}
          </b-tooltip>
        </template>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import ParameterizedSelect from "@/components/base/ParameterizedSelect";
import * as fieldTypes from "@/constants/form_field_types_identifiers";

export default {
  name: "GenericFormField",
  components: { ParameterizedSelect },
  emits: ["input", "change", "update"],
  props: {
    fieldName: {
      type: String,
      required: false,
      default: "",
    },
    fieldProperties: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    value: {
      type: undefined,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    error: {
      type: [String, Array],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      fieldTypes,
    };
  },
  computed: {
    inputId() {
      return `${this.fieldName.replace("_", "-")}`;
    },
    state() {
      return this.error ? false : null;
    },
    fieldLabel() {
      return this.label || this.fieldProperties.label;
    },
  },
  methods: {
    onInput(value) {
      this.$emit("input", value);
    },
    onChange(value) {
      this.$emit("change", value);
    },
    onUpdate(value) {
      this.$emit("update", value);
    },
  },
};
</script>

<style scoped lang="sass">
@import '~bootstrap/scss/bootstrap.scss'

.custom-tel-input
  display: flex

  &:focus-within
    color: $input-focus-color
    background-color: $input-focus-bg
    border-color: $input-focus-border-color
    outline: 0
    @if $enable-shadows
      @include box-shadow($input-box-shadow, $input-focus-box-shadow)
    @else
      box-shadow: $input-focus-box-shadow
</style>
