<template>
  <b-button
    v-bind:pill="pill"
    v-bind:variant="variant"
    v-bind:style="styles"
    v-on:click="$emit('click')"
  >
    <b-icon v-bind:icon="icon" />
  </b-button>
</template>

<script>
export default {
  name: "IconButton",
  props: {
    variant: {
      type: String,
      required: false,
      default: "primary",
    },
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: "md",
    },
    pill: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      sizes: {
        sm: "31px",
        md: "38px",
        lg: "48px",
      },
    };
  },
  computed: {
    styles() {
      const size = Object.keys(this.sizes).includes(this.size)
        ? this.sizes[this.size]
        : this.size;
      return {
        "--size": size,
      };
    },
  },
};
</script>

<style scoped lang="sass">
button
  display: flex
  align-items: center
  justify-content: center
  padding: 0
  font-size: calc(#{var(--size)} * 12/19)
  min-width: var(--size)
  height: var(--size)
</style>
