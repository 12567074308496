<template>
  <Spinner v-if="isGenerationProcessed"
    >Generating{{ processingText }}...</Spinner
  >
  <BaseForm v-else v-bind="formData" v-on:submit="onSubmit" />
</template>

<script>
import BaseForm from "@/components/base/BaseForm";
import Spinner from "@/components/base/Spinner";

export default {
  name: "GenerationForm",
  components: { BaseForm, Spinner },
  props: {
    targetUrl: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    max: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        fieldData: {
          amount: {
            label: "Amount:",
            type: "number",
            min: 1,
            max: this.max,
            required: true,
          },
        },
        modelData: { amount: 1 },
        errors: {},
        formName: `${
          this.name ? this.name.toLowerCase().replace(/ /g, "-") + "-" : ""
        }generation-form`,
      },
      isGenerationProcessed: false,
      processingText: this.name ? ` ${this.name}` : "",
    };
  },
  methods: {
    onSubmit() {
      this.isGenerationProcessed = true;
      this.axios
        .post(this.targetUrl, this.formData.modelData)
        .then(() => this.$emit("success", this.formData.modelData))
        .catch((error) => {
          this.isGenerationProcessed = false;
          if (error.response.status === 400) {
            this.formData.errors = { amount: error.response.data.message };
          } else {
            this.formData.errors = { _internal: error.response.statusText };
          }
        });
    },
  },
};
</script>
