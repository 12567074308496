<template>
  <div>
    <b-table
      bordered
      hover
      v-bind:items="items"
      v-bind:fields="fields"
      headVariant="light"
    >
      <template v-slot:cell(options)="row">
        <b-button
          size="sm"
          variant="primary"
          v-on:click="handleDelete(row.item.id)"
          class="delete-payment-option-button"
        >
          <b-icon icon="trash" />
        </b-button>
      </template>
    </b-table>
    <b-modal
      id="modal-payment-option-delete"
      title="Remove payment option"
      cancel-variant="outline-primary"
      v-on:ok="deletePaymentOption"
    >
      <p class="my-4">Are you sure you want to remove this payment option?</p>
    </b-modal>
    <b-modal
      id="modal-payment-option-create"
      title="Create new payment option"
      hide-footer
      lazy
    >
      <ApiForm
        v-bind="apiFormOptions"
        v-on:success="handlePaymentOptionCreation"
      />
    </b-modal>
    <div>
      <b-button
        variant="primary"
        v-on:click="$bvModal.show('modal-payment-option-create')"
      >
        Add new payment option.
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  PAYMENT_OPTIONS_ROUTE,
  PAYMENT_OPTION_DETAILS_ROUTE,
  SUPPLIER_TOKENS_ROUTE,
} from "@/constants/relative_api_routes";
import ApiForm from "@/components/base/ApiForm";

export default {
  name: "ThePaymentOptionsManager",
  components: { ApiForm },
  props: {
    items: {
      type: Array,
      required: true,
    },
    merchantId: {
      type: String,
      required: true,
    },
    spaceId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "currency_display_name",
          label: "Currency",
        },
        "token",
        "ratio_to_base_currency",
        "options",
      ],
      tokenList: [],
      selectedPaymentOptionId: null,
    };
  },
  computed: {
    apiFormOptions() {
      return {
        formName: "payment-option-create",
        targetUrl:
          this.appConfig.BACKEND_API_URL + PAYMENT_OPTIONS_ROUTE(this.spaceId),
        hiddenFields: ["space", "merchant"],
        customFieldOptions: {
          space: { default: this.spaceId },
          merchant: { default: this.merchantId },
          token: { choices: this.tokenList },
          ratio_to_base_currency: { default: 1 },
        },
        conditionalFields: new Map([
          [
            ["token", "ratio_to_base_currency"],
            { currency: (value) => value === "TOKEN" },
          ],
        ]),
      };
    },
  },
  methods: {
    handleDelete(id) {
      this.selectedPaymentOptionId = id;
      this.$bvModal.show("modal-payment-option-delete");
    },
    deletePaymentOption() {
      this.axios
        .delete(
          this.appConfig.BACKEND_API_URL +
            PAYMENT_OPTION_DETAILS_ROUTE(this.selectedPaymentOptionId)
        )
        .then(() => {
          this.$emit("listStateChanged");
          this.selectedPaymentOptionId = null;
        });
    },
    handlePaymentOptionCreation() {
      this.$bvModal.hide("modal-payment-option-create");
      this.$emit("listStateChanged");
    },
    getTokens() {
      this.axios
        .get(
          this.appConfig.BACKEND_API_URL + SUPPLIER_TOKENS_ROUTE(this.spaceId)
        )
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            this.tokenList.push({
              value: response.data[i].id,
              text: response.data[i].name,
            });
          }
        });
    },
  },
  created() {
    this.getTokens();
  },
};
</script>
