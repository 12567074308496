import Vue from "vue";
import VueRouter from "vue-router";
import * as pageNames from "@/constants/page_names";
import * as navGuards from "@/router/navigation_guards";
import Home from "@/views/Home.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import TokenCreate from "@/views/TokenCreate";
import TokenList from "@/views/TokenList";
import Tokens from "@/views/Tokens";
import SpaceList from "@/views/SpaceList";
import Spaces from "@/views/Spaces";
import SpaceCreate from "@/views/SpaceCreate";
import SpaceDetails from "@/views/SpaceDetails";
import Offers from "@/views/Offers";
import OfferList from "@/views/OfferList";
import OfferCreate from "@/views/OfferCreate";
import OfferDetails from "@/views/OfferDetails";
import Merchant from "@/views/Merchant";
import Wallets from "@/views/Wallets";
import WalleeInstaller from "@/views/WalleeInstaller";
import Cashier from "@/views/Cashier";
import Cashiers from "@/views/Cashiers";
import PromoRules from "@/views/PromoRules";
import PromoRuleList from "@/views/PromoRuleList";
import PromoRuleCreate from "@/views/PromoRuleCreate";
import PromoRuleDetails from "@/views/PromoRuleDetails";
import GlobalPromoRules from "@/views/GlobalPromoRules";
import GlobalPromoRuleList from "@/views/GlobalPromoRuleList";
import GlobalPromoRuleCreate from "@/views/GlobalPromoRuleCreate";
import GlobalPromoRuleDetails from "@/views/GlobalPromoRuleDetails";
import Scanner from "@/views/Scanner";
import BalanceScanner from "@/views/BalanceScanner";
import Wallet from "@/views/Wallet";
import Collectors from "@/views/Collectors";
import Dashboard from "@/views/Dashboard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: pageNames.HOME_PAGE,
    component: Home,
  },
  {
    path: "/sign-in",
    name: pageNames.SIGN_IN,
    component: SignIn,
    beforeEnter: navGuards.ifNotAuthenticated,
  },
  {
    path: "/sign-up",
    name: pageNames.SIGN_UP,
    component: SignUp,
    beforeEnter: navGuards.ifNotAuthenticated,
  },
  {
    path: "/merchant",
    name: pageNames.MERCHANT,
    component: Merchant,
  },
  {
    path: "/tokens",
    component: Tokens,
    children: [
      {
        /**
         * Path can be replaced with "".
         * It works the same, except for a trailing slash appearing in URL, when we use the navbar.
         * In order to keep consistency with other paths and prevent trailing slash from appearing,
         * the path has to be the same as parent.
         * In either case, TokenList can still be accessed with both /tokens and /tokens/.
         */
        path: "/tokens",
        name: pageNames.TOKENS,
        component: TokenList,
      },
      {
        path: "create",
        name: pageNames.TOKEN_CREATE,
        component: TokenCreate,
      },
    ],
  },
  {
    path: "/spaces",
    component: Spaces,
    children: [
      {
        path: "/spaces",
        name: pageNames.SPACES,
        component: SpaceList,
      },
      {
        path: "create",
        name: pageNames.SPACE_CREATE,
        component: SpaceCreate,
      },
      {
        path: "details/:id",
        name: pageNames.SPACE_DETAILS,
        component: SpaceDetails,
      },
    ],
  },
  {
    path: "/offers",
    component: Offers,
    children: [
      {
        path: "/offers",
        name: pageNames.OFFERS,
        component: OfferList,
      },
      {
        path: "create",
        name: pageNames.OFFER_CREATE,
        component: OfferCreate,
      },
      {
        path: "details/:id",
        name: pageNames.OFFER_DETAILS,
        component: OfferDetails,
      },
    ],
  },
  {
    path: "/promo-rules",
    component: PromoRules,
    children: [
      {
        path: "/promo-rules",
        name: pageNames.PROMO_RULES,
        component: PromoRuleList,
      },
      {
        path: "create",
        name: pageNames.PROMO_RULE_CREATE,
        component: PromoRuleCreate,
      },
      {
        path: "details/:id",
        name: pageNames.PROMO_RULE_DETAILS,
        component: PromoRuleDetails,
      },
    ],
  },
  {
    path: "/global-rules",
    component: GlobalPromoRules,
    children: [
      {
        path: "/global-rules",
        name: pageNames.GLOBAL_PROMO_RULES,
        component: GlobalPromoRuleList,
      },
      {
        path: "create",
        name: pageNames.GLOBAL_PROMO_RULE_CREATE,
        component: GlobalPromoRuleCreate,
      },
      {
        path: "details/:id",
        name: pageNames.GLOBAL_PROMO_RULE_DETAILS,
        component: GlobalPromoRuleDetails,
      },
    ],
  },
  {
    path: "/wallets",
    name: pageNames.WALLETS,
    component: Wallets,
  },
  {
    path: "/wallet",
    name: pageNames.WALLET,
    component: Wallet,
  },
  {
    path: "/wallee-installation",
    name: pageNames.WALLEE_INSTALLATION,
    component: WalleeInstaller,
  },
  {
    path: "/cashiers",
    name: pageNames.CASHIERS,
    component: Cashiers,
  },
  {
    path: "/cashier",
    name: pageNames.CASHIER,
    component: Cashier,
  },
  {
    path: "/scanner",
    name: pageNames.SCANNER,
    component: Scanner,
  },
  {
    path: "/balance",
    name: pageNames.BALANCE_SCANNER,
    component: BalanceScanner,
  },
  {
    path: "/collectors",
    name: pageNames.COLLECTORS,
    component: Collectors,
  },
  {
    path: "/dashboard",
    name: pageNames.DASHBOARD,
    component: Dashboard,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(navGuards.globalBeforeEachGuard);

export default router;
