export default {
  methods: {
    parseDatetime(dateData) {
      let date = new Date(Date.parse(dateData));
      return Intl.DateTimeFormat("en-GB", {
        timeZone: "UTC",
        dateStyle: "full",
        timeStyle: "long",
      }).format(date);
    },
    parseDatetimeToDateOnly(dateString) {
      return dateString.split("T")[0];
    },
  },
};
