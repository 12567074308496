<template>
  <div id="sign-up">
    <h1>Create new merchant account</h1>
    <div class="form-container">
      <BaseForm
        v-if="!emailVerification.isWhitelisted"
        v-bind:fieldData="whitelistLookupForm.fieldData"
        v-bind:modelData="whitelistLookupForm.modelData"
        v-bind:errors="whitelistLookupForm.errors"
        formName="whitelist-lookup"
        v-on:submit="lookupEmail"
      />
      <ApiForm
        v-else
        v-bind="apiFormOptions"
        v-on:processing="$bvModal.show(processingModalID)"
        v-on:fail="$bvModal.hide(processingModalID)"
        v-on:success="onSuccess($event)"
      />
      <b-modal
        v-bind:id="processingModalID"
        hide-footer
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        centered
      >
        <Spinner>Please wait while we set everything up.</Spinner>
      </b-modal>
      <b-modal
        v-bind:id="mnemonicModalID"
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        ok-only
        v-bind:ok-disabled="!mnemonicChecked"
        v-on:hide="showQrcode"
      >
        <h3>
          This is the mnemonic phrase for your dedicated wallet. It is crucial
          that you write it down and keep it a secret.
        </h3>
        <div id="mnemonic-container">
          {{ mnemonic }}
        </div>
        <b-form-checkbox v-model="mnemonicChecked">
          <span id="mnemonic-checkbox-text">
            I have written down my mnemonic phrase
          </span>
        </b-form-checkbox>
      </b-modal>
      <b-modal
        v-bind:id="qrcodeModalID"
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        ok-only
        v-bind:ok-disabled="!qrcodeChecked"
        v-on:hide="finishSignUpProcess"
      >
        <h3>Qr code for your authenticator app</h3>
        <canvas ref="qrcode"></canvas>
        <p>Authenticator secret in text form: {{ otpSecret }}</p>
        <b-form-checkbox v-model="qrcodeChecked">
          <span id="mnemonic-checkbox-text">
            I added qr code to my authenticator app
          </span>
        </b-form-checkbox>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  MERCHANT_LIST_CREATE,
  WHITELIST_LOOKUP_ROUTE,
} from "@/constants/relative_api_routes";
import ApiForm from "@/components/base/ApiForm";
import BaseForm from "@/components/base/BaseForm";
import TheSignUpForm from "@/components/auth/TheSignUpForm";
import { SIGN_IN, WALLEE_INSTALLATION } from "@/constants/page_names";
import {
  PHONE_FIELD,
  TEXT_FIELD,
  TEXTAREA_FIELD,
} from "@/constants/form_field_types_identifiers";
import Spinner from "@/components/base/Spinner";
import * as QRCode from "qrcode";

export default {
  name: "SignUp",
  components: { Spinner, ApiForm, BaseForm },
  data() {
    return {
      processingModalID: "processing-modal",
      mnemonicModalID: "mnemonic-modal",
      qrcodeModalID: "qrcode-modal",
      mnemonic: null,
      mnemonicChecked: false,
      qrcodeChecked: false,
      otpUrl: null,
      otpSecret: null,
      emailVerification: {
        isWhitelisted: false,
        isNonCoControlled: false,
      },
      whitelistLookupForm: {
        fieldData: {
          email: {
            type: "email",
            required: true,
            label: "Email",
          },
        },
        modelData: {},
        errors: {},
      },
    };
  },
  methods: {
    onSuccess(data) {
      this.$bvModal.hide(this.processingModalID);
      this.mnemonic = data["mnemonic_phrase"] || null;
      this.otpUrl = data["otp_url"];
      this.otpSecret = data["otp_secret"];
      if (this.mnemonic) {
        this.$bvModal.show(this.mnemonicModalID);
      } else {
        this.showQrcode();
      }
    },
    finishSignUpProcess() {
      this.$router.push(this.redirectPath);
    },
    lookupEmail() {
      this.axios
        .get(
          this.appConfig.BACKEND_API_URL +
            WHITELIST_LOOKUP_ROUTE(this.whitelistLookupForm.modelData.email)
        )
        .then(({ data }) => {
          this.emailVerification.isWhitelisted = true;
          this.emailVerification.isNonCoControlled = data["no_co_control"];
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.whitelistLookupForm.errors = {
              other: ["This email has not been registered with CENTI Ltd."],
            };
          } else {
            this.whitelistLookupForm.errors = {
              other: ["Something went wrong. Please try again later."],
            };
          }
        });
    },
    showQrcode() {
      this.$bvModal.show(this.qrcodeModalID);
      // Give time for the canvas to be created before trying to access reference to it
      this.$nextTick(() => {
        QRCode.toCanvas(this.$refs.qrcode, this.otpUrl, function (error) {
          if (error) console.error(error);
        });
      });
    },
  },
  computed: {
    apiFormOptions() {
      const formData = {
        formName: "signup",
        formComponent: TheSignUpForm,
        targetUrl: `${this.appConfig.BACKEND_API_URL}${MERCHANT_LIST_CREATE}`,
        hiddenFields: [
          "spaces_supplier",
          "email",
          !this.emailVerification.isNonCoControlled ? "password" : "",
        ],
        customFieldOptions: {
          phone_number: { type: PHONE_FIELD },
          description: { type: TEXTAREA_FIELD },
          paymail: {
            type: TEXT_FIELD,
            label: "Pay Mail prefix",
            help_text:
              "This Pay Mail address will be used in exchanging tokens with customers",
          },
          mnemonic_phrase: {
            type: TEXT_FIELD,
            label: "Mnemonic phrase",
            help_text: "If you wish to provide an already existing wallet",
          },
          email: { default: this.whitelistLookupForm.modelData.email },
        },
      };
      if (this.emailVerification.isNonCoControlled) {
        formData.customFieldOptions.password = {
          type: "password",
          label: "Password",
          required: true,
        };
      }
      return formData;
    },
    redirectPath() {
      const targetPath = this.$route.query.targetPath;
      if (targetPath) {
        const isTargetPathCorrect =
          this.$router.resolve(targetPath.toString()).route.name ===
          WALLEE_INSTALLATION;
        return isTargetPathCorrect ? targetPath : { name: SIGN_IN };
      }
      return { name: SIGN_IN };
    },
  },
};
</script>

<style lang="sass" scoped>
#mnemonic-checkbox-text
  font-style: italic
  font-weight: 300

#mnemonic-container
  background: whitesmoke
  font-family: monospace
  font-size: 20px
  color: dimgray
  padding: 5px
  margin: 15px 0
</style>
